import { RepositoryFactory } from '@/api/repositoryFactory'
import i18n from '../js/language'
const devcodeID = RepositoryFactory.get('devcode')
const userRepository = RepositoryFactory.get('users')
import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbar'
import { useUsersStore } from '../stores/users'
import router from '../js/router'

export const useOnboardingsStore = defineStore('onboardings', {
  state: () => ({
    loading: false,
    loadingContact: false,
    loadingRequested: false,
    user: null,
    activeTheme: 'light',
    otpVerified: false,
    joinRequested: false,
    salesContacted: false,
    loadingKYC: false,
    submitFormStatus: '',
    occupationList: [],
    submitFormErrors: [],
    errorStep: '',
    kycPayload: {
      baseCurrency: 'EUR',
      assetClass: 'STK',
      email: '',
      username: '',
      salutation: 'Mr.',
      firstName: '',
      lastName: '',
      maritalStatus: '',
      dob: '',

      dependentNo: '',
      countryOfContact: '',
      contactNo: '',
      countryOfBirth: '',

      streetOfResidence: '',
      cityOfResidence: '',
      postalCodeOfResidence: '',
      countryOfResidence: '',
      stateOfResidence: '',

      mailSameAsResidence: null,
      streetOfMail: '',
      cityOfMail: '',

      countryOfMail: '',
      stateOfMail: '',
      postalCodeOfMail: '',

      citizenship: '',
      issuingCountry: '',

      issuedDocumentType: '',
      issuedDocumentNumber: '',

      issuingState: '',
      issuingRta: '',
      citizenMediCardColor: '',
      citizenMediCareRefNo: '',

      issuedDocumentHasExpiry: false,
      issuedDocumentExpiryDate: '',

      checkCitizenExpDate: false,
      citizenExpDate: '',
      openCitizenExpDate: false,

      employType: '',
      occupationList: null,
      occupation: '',
      employer: '',
      employerBusiness: '',

      empAddressSameAsResidence: true,
      employerStreet: '',
      employerCity: '',
      employerState: '',
      employerPostal: '',
      employerCountry: '',

      taxCountry: '',

      netWorth: '',
      liquidNetWorth: '',
      annualNetIncome: '',

      sourceWealthType: '',
      customSourceOfIncome: '',

      investmentObjective: '',

      knowledgeLevel: '',
      tradesPerYear: '',
      yearsTrading: '',
      signature: '',
      agreed: false,
      step: 0
    }
  }),
  actions: {
    setKycPayload (key, value) {
      this.kycPayload = {
        ...this.kycPayload,
        [key]: value
      }

      // console.log(this.kycPayload)


      localStorage.kycPayload = JSON.stringify(this.kycPayload)
    },
    resetPhoneForm () {
      this.salesContacted = false
    },
    resetJoinRequest () {
      this.joinRequested = false
    },
    async iframe (payload) {
      this.loading = true
      const response = await devcodeID.devcode(payload)
      if (response.status === 200 && response.data) {
        this.loading = false
        return response.data
      }
    },
    async requestJoinExchange (payload) {
      try {
        this.loadingRequested = true
        const response = await userRepository.requestJoinExchange(payload)
        if (response.status === 200 && response.data) {
          this.joinRequested = true
          this.loadingRequested = false
          return response.data
        }
      } catch (e) {
        this.loadingRequested = false
        useSnackbarStore().snack({
          text: i18n.global.t('Could not send a join request, please try again later'),
          type: 'error',
          show: true,
          autoclose: false
        })
      }
    },
    async requestJoin (payload) {
      try {
        this.loadingRequested = true
        const response = await userRepository.requestJoin(payload)
        if (response.status === 200) {
          this.joinRequested = true
          this.loadingRequested = false
          return response.data
        }
      } catch (e) {
        this.loadingRequested = false
        useSnackbarStore().snack({
          text: i18n.global.t('Could not send a join request, please try again later'),
          type: 'error',
          show: true,
          autoclose: false
        })
      }
    },
    async iframeResponse (payload) {
      this.loading = true
      const response = await devcodeID.auth(payload)
      this.loading = false
      return response
    },
    async otpVerification (payload) {
      try {
        this.loading = true
        const response = await userRepository.verifyOTP(payload)
        console.log(response)
        if (response.status === 200) {
          this.loading = false
          if (response.data.status === 'success') {
            this.otpVerified = true
            return 'success'
          } else {
            return response.data
          }
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          text: i18n.global.t('Sorry, the One-Time-Password does not match. Try Again'),
          type: 'error',
          show: true,
          autoclose: false
        })
      }
    },
    async sendOTP (payload) {
      try {
        this.loading = true
        const response = await userRepository.sendOTP(payload)
        if (response.status === 200) {
          this.loading = false
          useSnackbarStore().snack({
            text: i18n.global.t('Code generated successfully. please check out email for the code'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.loading = false

        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async contactSalesExchanges (payload) {
      try {
        this.loadingContact = true
        const response = await userRepository.contactSalesExchange(payload)
        if (response.status === 200) {
          this.salesContacted = true
          this.loadingContact = false
          useSnackbarStore().snack({
            text: i18n.global.t('Code generated successfully. please check out email for the code'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.loading = false

        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async contactSales (payload) {
      try {
        this.loadingContact = true
        const response = await userRepository.contactSales(payload)
        if (response.status === 200) {
          this.salesContacted = true
          this.loadingContact = false
          useSnackbarStore().snack({
            text: i18n.global.t('Code generated successfully. please check out email for the code'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.loading = false

        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async sendPDF (payload) {
      try {
        this.loading = true

        const response = await userRepository.sendPDF(payload)
        if (response.status === 200) {
          this.loading = false
          useSnackbarStore().snack({
            text: response.message ?? i18n.global.t('If user exists, you will receive an email with instructions.'),
            type: 'info',
            show: true,
            autoclose: true
          })
          return 'success'
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async storeUserDetails () {
      try {
        this.loadingStoreUserDetails = true

        const payload = {
          ...this.kycPayload,
          occupationList: null,
        }

        const response = await userRepository.storeUserDetails(payload)
        if (response.status === 200) {
          this.loadingStoreUserDetails = false

          if (response.data) {
            useUsersStore().setUser(response.data)
          }

          return response.data
        } else {
          console.log(response)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
        this.loadingStoreUserDetails = false
      }
    },
    async submitForm () {
      try {
        this.loadingKYC = true

        const response = await userRepository.submitKYCForm()

        if (response.status === 200 && response.data) {
          this.submitFormStatus = response.data.status
          if (this.submitFormStatus === 'Already processed') {
            setTimeout(function () {
              router.push({
                name: 'myEucapsSettings'
              })
            }, 5000)
          } else if (this.submitFormStatus === 'Step error') {
            this.errorStep = response.data?.step
            this.submitFormErrors = response.data?.Errors
          }
          else if (this.submitFormStatus === 'Error') {
            if (typeof response.data.errors === 'string') {
              if (this.submitFormErrors.length > 0) {
                this.submitFormErrors = []
              }

              let errorString = response.data.errors
              if (errorString.includes('KnowledgeLevel')) {
                errorString = i18n.global.t('Your Investment Knowledge is insufficient for Stock Trading')
              } else if (errorString.includes('Investment Experience')) {
                errorString = i18n.global.t('Your Trading Experience is insufficient for Stock Trading')
              }

              this.submitFormErrors.push(errorString)
            } else if (typeof response.data.errors.Error === 'string') {
              if (this.submitFormErrors.length > 0) {
                this.submitFormErrors = []
              }
              let errorString = response.data.errors.Error
              if (errorString.includes('KnowledgeLevel')) {
                errorString = i18n.global.t('Your Investment Knowledge is insufficient for Stock Trading')
              } else if (errorString.includes('Investment Experience')) {
                errorString = i18n.global.t('Your Trading Experience is insufficient for Stock Trading')
              }

              this.submitFormErrors.push(errorString)
            } else if (response.data.errors && response.data.errors.length > 0) {
              for (let i = 0; i < response.data.errors.length; i++) {
                let errorString = JSON.stringify(response.data.errors[i])

                if (errorString.includes('KnowledgeLevel')) {
                  errorString = i18n.global.t('Your Investment Knowledge is insufficient for Stock Trading')
                } else if (errorString.includes('Investment Experience')) {
                  errorString = i18n.global.t('Your Trading Experience is insufficient for Stock Trading')
                }
                this.submitFormErrors.push(errorString)
              }
            } else {
              this.submitFormErrors = response.data.errors.Error
            }
            console.log('this.submitFormErrors', this.submitFormErrors)
          }
        } else {
          this.submitFormStatus = 'E'
        }
        console.log(response)
      } catch (e) {

        console.error(e)


        if (e.response && e.response.data && e.response.data.status) {
          this.submitFormStatus = e.response.data.status
          if (this.submitFormStatus === 'Step error') {
            this.errorStep = e.response.data?.step
            this.submitFormErrors = e.response.data?.Errors
          }
        } else {
          this.submitFormStatus = 'E'
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }


      }
      finally {
        this.loadingKYC = false

      }
    },

    async sendExhangePDF (payload) {
      try {
        this.loading = true

        const response = await userRepository.sendPDF(payload)
        if (response.status === 200) {
          this.loading = false
          useSnackbarStore().snack({
            text: response.message ?? i18n.global.t('If user exists, you will receive an email with instructions.'),
            type: 'info',
            show: true,
            autoclose: true
          })
          return 'success'
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },

    async submitDocuments (payload) {
      try {
        this.loading = true
        const response = await userRepository.submitDocuments(payload)
        return response.status
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    }
  }
})

<template>
  <div v-if="notification.notifiable">
    <div>
      <Notification
        :pivot="notification.pivot"
        @remove="$emit('remove', { id: notification.id })"
      >
        <div @click="$parent.redirectPortfolio({ notificationId: notification.id, query: { navigate: 'tradingRef' } })">
          <div>
            {{ 'Your trading order for' }}
            {{ notification.notifiable.tradingOrder.side === 'BUY' ? 'buying' : 'selling' }}
            {{ notification.notifiable.tradingOrder.quantity + ' ' + $t('stocks of') }}
            <RouterLink
              class="font-bold"
              :to="{ name: 'companyOverview', params: { id: notification.notifiable.tradingOrder.company.slug_url } }"
            >
              {{ notification.notifiable.tradingOrder.company.name }}
            </RouterLink> {{ ' is ' + notification.notifiable.tradingOrder.status }}
          </div>
          <div class="text-xs mr-6">
            <span class="font-bold">
              {{ time }}</span>
          </div>
        </div>
      </Notification>
    </div>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationTradingOrderStatusChanged',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => { } },
    time: { type: String, default: '' }
  },
  emits: [
    'remove'
  ],
}
</script>

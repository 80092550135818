<template>
  <div>
    <div class="flex flex-col items-center justify-center space-y-6">
      <NeutralButton
      
        type="button"
        class="w-full text-md focus:outline-none disabled:opacity-50 flex items-center justify-center gap-2"
        @click="$emit('verify', 'trulioo')"
      >
        <div class="flex items-center justify-center">
          <img
            class="w-12"
            src="/img/Trulioo-Icon-White-RGB.png"
          >
        </div>
        <div
          class="font-semibold text-white self-center"
        >
          Verify by Trulioo
        </div>
      </NeutralButton>

      <NeutralButton
        type="button"
        no-padding
        class="w-full text-md focus:outline-none disabled:opacity-50 flex items-center justify-center py-1"

        @click="$emit('verify', 'devcode')"
      >
        <div class="flex items-center justify-center">
          <img
            class="w-12"
            src="/img/bankid.svg"
          >
        </div>
        <div
          class="font-semibold text-white self-center"
        >
          Verify by BankID
        </div>
      </NeutralButton>

 
      <!-- <SquareButton
        class="mb-2 border-gray-300 hover:bg-gray-100 w-64 h-12 flex items-center justify-start"
        @click="$emit('verify', 'freja')"
      >
        <div class="w-20 flex items-center justify-center">
          <img
            class="w-16"
            src="/img/freja_black.svg"
          >
        </div>
        <div class="text-sm font-bold text-custom-purple-500 self-center pr-2">
          Verify by Freja
        </div>
      </SquareButton> -->
    </div>
    <div class="flex justify-center space-x-4 px-2 pt-4 mt-8">
      <span class=" opacity-60 text-right text-sm self-center font-medium leading-tight">
        Do you feel ready to browse Eucaps? <br>You can do the last steps later
      </span>
      <SquareButton
        class=" bg-gray-300 rounded-lg text-base text-custom-purple-500 font-bold border-none px-6 py-2 "
        @click="$emit('skip', $event)"
      >
        Skip for now
      </SquareButton>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VerificationButtons',
emits: [
'verify',
'skip'
]
}
</script>

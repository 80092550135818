<template>
  <CompanyFollow
    :is-followed="internalIsFollower"
    :text="text"
    :rounded-button="roundedButton"
    :text-colour="textColour"
    :small-padding="smallPadding"
    :is-menu="isMenu"
    @follow="follow"
  />
</template>

<script>
import CompanyFollow from './_components/CompanyFollow'
import { useCompaniesStore } from '../../stores/companies'
import { useUsersStore } from '../../stores/users'
import { useExchangesStore } from '../../stores/exchanges'

export default {
  name: 'CompanyFollowModule',
  components: {
    CompanyFollow
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    text: {
      type: Boolean,
      default: false
    },
    roundedButton: {
      type: Boolean,
      default: false
    },
    textColour: {
      type: String,
      default: ''
    },
    smallPadding: {
      type: Boolean, 
      default: false
    },
    isEpp: {
      type: Boolean,
      default: false
    },
    isMenu: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const companiesStore = useCompaniesStore()
    const usersStore = useUsersStore()
    const exchangesStore = useExchangesStore()

  return { companiesStore, usersStore, exchangesStore }
  },
  computed: {
    selectedCompany() {
      return this.isEpp? this.exchangesStore.exchange.exchange : this.companiesStore.selectedCompany
    },
    user() {
      return this.usersStore.user
    },
    usedCompany () {
      if (this.company) {
        return this.company
      }

      return this.selectedCompany
    },
    internalIsFollower () {
      return this.usedCompany?.is_follower?? this.user?.profile?.followed_company_ids?.includes(this.usedCompany.id)
    }
  },
  // mounted () {
  //   this.internalIsFollower = this.usedCompany?.is_follower
  // },
  methods: {
    follow () {
      if (this.user === undefined || this.user.profile === undefined || this.user.profile_type === 'App\\Models\\GuestUser') {
        this.$router.push({ name: 'login' })
        return
      }
      if (this.isEpp) {
        if (this.internalIsFollower) {
          this.exchangesStore.unfollowExchange(this.usedCompany.id)
        }
        else this.exchangesStore.followExchange(this.usedCompany.id)
      }
      else {
        this.companiesStore.follow(this.usedCompany.id)
      }

      // this.internalIsFollower = !this.internalIsFollower
    },
  }
}
</script>

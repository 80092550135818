<template>
  <div class="grid grid-cols-1">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Grid'
}
</script>

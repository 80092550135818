import { post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/highscores`

const getCompanies = async (data) => post(`${url}/companies`, data)
const getStockUsers = async (data) => post(`${url}/stock-users`, data)
export default {
  getCompanies,
  getStockUsers
}

<template>
  <div
    class="relative lg:absolute inset-0"
    :style="heightStyle"
  >
    <div
      :style="[{width: width + 'px'}]"
      class=" h-full flex-shrink-0 grid grid-cols-3 grid-rows-3"
    >
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Grid3X3',
  props: {
    breakpoint: {
      type: Number,
      default: 1024
    }

  },
  data () {
    return {
      parent: null,
      internalOrientation: 'width',
      bodyWidth: 0,
      scrollWidth: 0
    }
  },
  computed: {
    heightStyle () {
      if (this.bodyWidth !== 0 && this.bodyWidth < (this.breakpoint - this.scrollWidth)) {
        return 'height: ' + this.bodyWidth + 'px; width: 100%;'
      }

      return null
    },
    width () {
      if (this.bodyWidth !== 0 && this.bodyWidth < (this.breakpoint - this.scrollWidth)) {
        return this.bodyWidth
      }
      if (!this.parent) {
        return 0
      }
      return this.parent.offsetHeight
    }
  },
  mounted () {
    this.parent = this.$el
    this.bodyWidth = document.body.clientWidth
    const self = this
    window.addEventListener('resize', function (event) {
      self.bodyWidth = document.body.clientWidth
    })
    this.scrollWidth = window.innerWidth - document.body.offsetWidth
  }
}
</script>

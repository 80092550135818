<template>
  <div :style="style">
    <div class="grid grid-cols-5 gap-0">
      <div
        class="col-span-1 lightViolet rounded-l rounded-tr"
        :style="boxSize"
      />
      <div
        class="col-span-1"
        :style="boxSize"
      />
      <div
        class="col-span-1"
        :style="boxSize"
      />
      <div
        class="col-span-1 orange rounded-t"
        :style="boxSize"
      />
      <div
        class="col-span-1"
        :style="boxSize"
      />
    </div>
    <div class="grid grid-cols-5 gap-0">
      <div
        class="col-span-1"
        :style="boxSize"
      />
      <div
        class="col-span-1 lightViolet"
        :style="boxSize"
      />
      <div
        class="col-span-1 orange"
        :style="boxSize"
      />
      <div
        class="col-span-1 mustard"
        :style="boxSize"
      />
      <div
        class="col-span-1 mustard rounded-r"
        :style="boxSize"
      />
    </div>
    <div class="grid grid-cols-5 gap-0">
      <div
        class="col-span-1 lightViolet rounded-l"
        :style="boxSize"
      />
      <div
        class="col-span-1 white"
        :style="boxSize"
      />
      <div
        class="col-span-1 white"
        :style="boxSize"
      />
      <div
        class="col-span-1 deepCyan"
        :style="boxSize"
      />
      <div
        class="col-span-1"
        :style="boxSize"
      />
    </div>
    <div class="grid grid-cols-5 gap-0">
      <div
        class="col-span-1"
        :style="boxSize"
      />
      <div
        class="col-span-1 mustard rounded-bl"
        :style="boxSize"
      />
      <div
        class="col-span-1 lightCyan"
        :style="boxSize"
      />
      <div
        class="col-span-1"
        :style="boxSize"
      />
      <div
        class="col-span-1 lightViolet rounded-r rounded-bl"
        :style="boxSize"
      />
    </div>
    <div class="grid grid-cols-5 gap-0">
      <div
        class="col-span-1"
        :style="boxSize"
      />
      <div
        class="col-span-1"
        :style="boxSize"
      />
      <div
        class="col-span-1 lightCyan rounded-b"
        :style="boxSize"
      />
      <div
        class="col-span-1"
        :style="boxSize"
      />
      <div
        class="col-span-1"
        :style="boxSize"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ColoredLogo',
  props: {
    squareSize: Number
  },
  computed: {
    style () {
      return {
        height: `${this.squareSize}px`,
        width: `${this.squareSize}px`
      }
    },
    boxSize () {
      return {
        height: `${this.squareSize / 5}px`,
        width: `${this.squareSize / 5}px`
      }
    }
  }
}
</script>
<style scoped>
.lightViolet {
  background-color: #ddcae6;
}
.orange {
  background-color: #f15b29;
}
.mustard {
  background-color: #ffe0a7;
}
.white {
  background-color: #ffff;
}
.deepCyan {
  background-color: #56f69b;
}
.lightCyan {
  background-color: #bbfbd7;
}
</style>

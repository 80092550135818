<template>
  <div />
</template>

<script>

export default {
  name: 'Empty'
}
</script>

<template>
  <img
    :class="big ? 'w-28 h-28' : 'w-14 h-14'"
    :srcset="srcset"
    :src="src"
  >
</template>

<script>
export default {
  name: 'Thumbnail',
  props: {
    srcset: { type: String, default: '' },
    src: { type: String, default: '' },
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>

import { get } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/insiders`

const list = async (search) => get(`${url}`, search)
const scroll = async (link, data) => get(`${link}`, data)

export default {
  list,
  scroll
}

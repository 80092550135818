<template>
  <div class="w-full">
    <hr
      class="px-1 border "
      :class="border"
    >
  </div>
</template>

<script>
export default {
  name: 'Divider',
  props: {
    border: { type: String, default: 'dark:border-custom-purple-200 dark:bg-custom-purple-200  border-custom-purple-300 bg-gray-200' }
  }
}
</script>

<template>
  <div class="page flex flex-grow flex-shrink-0 flex-col bg-main-background dark:bg-custom-purple-500">
    <HeaderContainer
      v-if="usersStore.user && usersStore.user.id && usersStore.user.profile_type !== 'App\\Models\\GuestUser'"
    />
    <FrontHeaderContainer
      v-else
    />
    <main class="flex flex-col flex-grow flex-shrink-0 z-10 mb-16">
      <div v-if="showFiltrations && !isMobile">
        <FiltrationsModuleContainer />
      </div>
      <RouterView :key="$route.fullPath" />
    </main>
    <FooterContainer v-if="$route.meta.needLogin !== false" />
    <FrontFooterContainer v-if="!(isMobile && parentRoute !== 'chat')" />
    <!-- <FairUsageModalContainer v-if="((user && user.id && user.profile_type === 'App\\Models\\GuestUser' || !user || !user.id) && $route.meta.needLogin !== false) || (!(user && user.id && user.profile_type !== 'App\\Models\\GuestUser') && $route.meta.needLogin !== false)" /> -->
    <InitialUserWizardModalContainer v-if="hasIntroBubble" />
    <PortalTarget
      name="dialog"
      multiple
    />
  </div>
</template>

<script>
import { useUsersStore } from '@/stores/users'

import FrontHeaderContainer from '@/modules/frontHeader'
import HeaderContainer from '@/modules/header'
import FooterContainer from '@/modules/footer'
// import FairUsageModalContainer from '@/modules/fairUsageModal'
import InitialUserWizardModalContainer from '@/modules/initialSwiperWizardModal'
import FrontFooterContainer from '@/modules/frontFooter'
import { PortalTarget } from 'vue3-portal'
import FiltrationsModuleContainer from '@/modules/filtrations'
import { isMobile } from '../js/helpers/mobile'

export default {
  name: 'StandardLayout',
  components: {
    HeaderContainer,
    FooterContainer,
    FrontFooterContainer,
    PortalTarget,
    // FairUsageModalContainer,
    InitialUserWizardModalContainer,
    FrontHeaderContainer,
    FiltrationsModuleContainer
  },
  setup () {
    const usersStore = useUsersStore()

    return { usersStore }
  },
  data () {
    return {
      introModalBubble: false
    }
  },
  computed: {
    isMobile () {
      return isMobile()
    },
    isFrontLayout () {
      if (this.$route.meta && this.$route.meta.layout) {
        return this.$route.meta.layout === 'FrontLayout'
      }

      return false
    },
    hasIntroBubble () {
      if (!localStorage.introBubbleSeen) {
        if (!this.usersStore.user || !this.usersStore.user.profile || !this.usersStore.user.profile.bubbles || this.usersStore.user.profile.bubbles.length === 0) {
          return false
        }

        let hasIntroBubble = false

        this.usersStore.user.profile.bubbles.map(b => {
          if (b.type === 'initial_user_wizard') {
            hasIntroBubble = true
          }
        })

        return hasIntroBubble
      }

      return false
    },
    parentRoute () {
      if (this.$route.matched.length > 1) {
        return this.$route?.matched[0]?.name
      }
      return this.$route.name
    },
    showFiltrations () {
      return this.parentRoute === 'explore' || this.parentRoute === 'forumAndDiscussions' || this.parentRoute === 'history'
    }
  }
}
</script>

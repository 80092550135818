<template>
  <img
    :class="[orange ? 'orange' : '', white ? 'white' : '']"
    :src="src"
  >
</template>

<script>
export default {
  name: 'SvgImage',
  props: {
    src: {
      type: String,
      default: ''
    },
    orange: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped >
.orange {
  filter: invert(42%) sepia(85%) saturate(728%) hue-rotate(335deg) brightness(93%) contrast(104%);
}
.white {
  filter: invert(99%) sepia(100%) saturate(0%) hue-rotate(352deg) brightness(105%) contrast(100%);
}
</style>

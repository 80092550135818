<template>
  <div class="flex items-center text-center p-2 rounded-lg border border-gray-200 dark:border-custom-purple-300 overflow-hidden">
    <FontAwesomeIcon
      :icon="currentType.icon"
      class="w-full text-4xl text-purple-500 dark:text-custom-purple-200 mr-2"
    />
    <a
      class="flex flex-col text-left text-xs font-semibold w-full"
      :href="src"
      target="_blank"
    >
      <span class="font-semibold w-full dark:text-custom-purple-100">{{ docName }}</span>

      <span class="font-semibold w-full text-purple-500 dark:text-custom-purple-100">{{ currentType.name }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'BaseDocument',
  props: {
    src: { type: String, default: '' },
    type: { type: String, default: '' },
    docName: { type: String, default: '' }
  },
  data () {
    return {
      types: [
        {
          icon: 'file-excel',
          name: 'EXCEL',
          extensions: [
            'xls', 'xlsx', 'csv'
          ]
        },
        {
          icon: 'file-powerpoint',
          name: 'POWERPOINT',
          extensions: [
            'pptx', 'ppt'
          ]
        },
        {
          icon: 'file-pdf',
          name: 'PDF',
          extensions: ['pdf']

        },
        {
          icon: 'file-word',
          name: 'WORD',
          extensions: [
            'doc', 'docx', 'odt'
          ]
        },
        {
          icon: 'file',
          name: 'TEXT',
          extensions: ['txt']

        }
      ]
    }
  },
  computed: {
    currentType () {
      let currentType = this.types.find(type => {
        return type.extensions.includes(this.type)
      })
      if (currentType) {
        return currentType
      }
      return this.types[0]
    }
  }
}
</script>

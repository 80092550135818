import { get, post, put, destroy, upload } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const login = async (data) => post(`${url}/login`, data)
const guest = async (data) => get(`${url}/guest`, data)
const sendOpt = async (data) => post(`${url}/send-otp`, data)
const dsecret = async (platformKey) => get(`${url}/dsecret/${platformKey}`)
const verifyEmailForForgotPassword = async (data) => post(`${url}/verify-email`, data)
const resetPassword = async (data) => post(`${url}/reset-password`, data)
const csrf = async (data) => get(`${process.env.VUE_APP_API_DOMAIN}/sanctum/csrf-cookie`, data)
const register = async (data) => post(`${url}/register`, data)
const checkAvailability = async (data) => get(`${url}/check-availability`, data)
const firebaseRegister = async (data) => post(`${url}/register/firebase`, data)
const one = async (id) => post(`${url}/users/${id}`)
const follow = async (id) => post(`${url}/users/${id}/follow`)
const block = async (id, data) => post(`${url}/users/${id}/block`, data)
const profileImage = async (data) => post(`${url}/v2/profile-image`, data)
const profile = async () => get(`${url}/profile`)
const me = async () => get(`${url}/me`)
const fetchSaved = async () => get(`${url}/users/save`)
const fetchFollowings = async (id) => get(`${url}/users/${id}/followings`)

const fetchFollowingsCount = async (id) => get(`${url}/users/${id}/followings-count`)
const fetchFollowingCompanies = async (id) => get(`${url}/users/${id}/following-companies`)
const fetchFollowingStockUsers = async (id) => get(`${url}/users/${id}/following-stock-users`)
const fetchFollowingLists = async (id) => get(`${url}/users/${id}/following-lists`)
const fetchFollowingThreads = async (id) => get(`${url}/users/${id}/following-threads`)
const fetchFollowingRooms = async (id) => get(`${url}/users/${id}/following-rooms`)
const fetchFollowingExchanges = async (id) => get(`${url}/users/${id}/following-exchanges`)

const fetchFollowers = async (id) => get(`${url}/users/${id}/followers`)
const scroll = async (link, data) => get(`${link}`, data)
const saveFCMToken = async (data) => post(`${url}/saveFCMToken`, data)
const fetchAllUsers = async (payload) => get(`${url}/users/fetch`, payload)
const fetchStockUserInfo = async (companyId, payload) => get(`${url}/users/${companyId}/fetch-user-info`, payload)
const setOnline = async () => get(`${url}/users/online`)
const setUserIdle = async () => post(`${url}/users/set-idle`)
const sendTradingVerificationEmail = async () => post(`${url}/users/send-trading-verification-email`)
const verifyTradingEmail = async (data) => post(`${url}/users/verify-trading-email`, data)
const resendTradingOtp = async () => post(`${url}/users/resend-trading-otp`)
const fetchIdleStatus = async () => get(`${url}/users/fetch-idle-state`)

const editRequest = async (payload) => put(`${url}/users/${payload.id}`, payload)
const editConfirmation = async (payload) => post(`${url}/users/${payload.id}/editConfirmation`, payload)
const deleteUser = async (payload) => destroy(`${url}/users/${payload.id}/delete`, payload)
const memberList = async () => get(`${url}/members`)
const removeSaved = async (id) => destroy(`${url}/saved/${id}`)
const fetchUserList = async (users) => get(`${url}/users/fetchUserList`, users)
const warningSeen = async (payload) => post(`${url}/warning-seen`, payload)
const emailVerificationNeeded = async (id) => post(`${url}/users/${id}/email-verification`)
const verifyEmailWithOTP = async (id, data) => post(`${url}/users/${id}/verify-email`, data)
const seenBubble = async (data) => post(`${url}/users/bubbles/seen`, data)
const visited = async (data) => post(`${url}/users/visited`, data)
const removeAllBubbles = async () => destroy(`${url}/removeAllBubbles`)
const frejaLogin = async (data) => post(`${url}/freja/login`, data)
const fetchMultiUsers = async () => get(`${url}/users/multi-users`)
const usersCompanies = async (id) => get(`${url}/users/${id}/companies`)
const addCompaniesToUser = async (id, data) => post(`${url}/users/${id}/add-companies-role`, data)
const removeCompaniesRole = async (id, data) => post(`${url}/users/${id}/remove-companies-role`, data)
const updateSubscription = async (data) => post(`${url}/updateSubscription`, data)
const checkSubscription = async () => get(`${url}/check-subscription`)
const checkPollSubscription = async () => get(`${url}/check-poll-subscription`)
const createCheckoutSession = async (data) => get(`${url}/create-checkout-session`, data)
const createCompanyCheckoutSession = async (companyId, data) =>
  get(`${url}/${companyId}/create-company-checkout-session`, data)
const createCompanyPollsCheckoutSession = async (companyId, data) =>
  get(`${url}/${companyId}/create-company-polls-checkout-session`, data)
const cancelUserSubscription = async () => post(`${url}/subscription/cancel-user-subscription`)
const addList = async (data) => post(`${url}/users/add-list`, data)
const fetchList = async () => get(`${url}/users/fetch-list`)
const fetchCompanyList = async (id) => get(`${url}/users/list/${id}`)
const addCompaniesToList = async (id, data) => post(`${url}/users/list/${id}/add-companies`, data)
const removeCompanyFromList = async (id, data) => post(`${url}/users/list/${id}/remove-companies`, data)
const removeList = async (id) => post(`${url}/users/list/${id}/remove`)
const followList = async (id, listId) => post(`${url}/users/${id}/list/${listId}/follow`)
const contactSalesExchange = async (payload) => post(`${url}/users/contact-sales-exchange`, payload)

const fetchCurrencies = async () => get(`${url}/currencies`)
const fetchCountries = async () => get(`${url}/countries`)
const fetchIsdCodes = async () => get(`${url}/country-isd-codes`)

const sendPDF = async (payload) => post(`${url}/users/send-user-agreement-mail`, payload)

const sendOTP = async (payload) => post(`${url}/users/send-otp`, payload)
const contactSales = async (payload) => post(`${url}/users/contact-sales`, payload)
const requestJoin = async (payload) => post(`${url}/users/request-join`, payload)
const requestJoinExchange = async (payload) => post(`${url}/users/request-join-exhange`, payload)

const sendSecondaryEmailOTP = async (payload) => post(`${url}/users/secondary-email-otp`, payload)
const verifyOTP = async (payload) => post(`${url}/users/verify-company-email`, payload)
const secondaryEmailverifyOTP = async (payload) => post(`${url}/users/verify-secondary-email`, payload)
const submitContact = async (payload) => post(`${url}/submit-contact`, payload)
const saveCurrency = async (payload) => post(`${url}/users/save-currency`, payload)
const isCompanyPremium = async () => get(`${url}/users/is-company-premium`)
const fetchInvestorSubscription = async () => get(`${url}/subscription/fetch-investor-subscription`)
const submitKYCForm = async () => post(`${url}/users/submit-kyc`)
const storeUserDetails = async (data) => post(`${url}/users/details`, data)
// const getOccupationList = async () => get(`${url}/dam/occupation-list`)
const getTradingAccountDetails = async () => get(`${url}/dam/get-details`)
const submitPoi = async (data) => upload(`${url}/dam/submit-poi`, data)
const submitPoa = async (data) => upload(`${url}/dam/submit-poa`, data)
const fetchCompanyReports = async () => get(`${url}/inderes/get/feeds/user/followed-companies`)
const uploadBanner = async (payload) => post(`${url}/banner-image`, payload)
const reportUser = async (data) => post(`${url}/incidents/${data.id}/report-user`, data)

const verifyOtpForLogin = async (data) => post(`${url}/verify-otp-for-login`, data)
const resendLoginOtp = async (data) => post(`${url}/resend-otp-for-login`, data)

//const getAgreementList = async () => get(`${url}/dam/get-form-links`)
const submitDocuments = async (data) => upload(`${url}/users/documents`, data)

export default {
  fetchFollowingExchanges,
  fetchFollowingCompanies,
  fetchFollowingStockUsers,
  fetchFollowingLists,
  fetchFollowingRooms,
  fetchFollowingThreads,
  fetchFollowingsCount,
  sendTradingVerificationEmail,
  fetchInvestorSubscription,
  isCompanyPremium,
  cancelUserSubscription,
  reportUser,
  saveCurrency,
  setUserIdle,
  resendTradingOtp,
  verifyTradingEmail,
  fetchIdleStatus,
  updateSubscription,
  checkSubscription,
  checkPollSubscription,
  createCheckoutSession,
  createCompanyCheckoutSession,
  createCompanyPollsCheckoutSession,
  addList,
  followList,
  removeList,
  removeCompanyFromList,
  fetchCompanyList,
  addCompaniesToList,
  fetchList,
  fetchStockUserInfo,
  addCompaniesToUser,
  removeCompaniesRole,
  contactSalesExchange,
  usersCompanies,
  frejaLogin,
  visited,
  seenBubble,
  dsecret,
  requestJoinExchange,
  login,
  fetchMultiUsers,
  guest,
  csrf,
  fetchFollowers,
  register,
  checkAvailability,
  one,
  firebaseRegister,
  follow,
  block,
  profileImage,
  profile,
  me,
  fetchSaved,
  scroll,
  saveFCMToken,
  fetchAllUsers,
  setOnline,
  editRequest,
  editConfirmation,
  deleteUser,
  memberList,
  removeSaved,
  fetchFollowings,
  fetchUserList,
  warningSeen,
  emailVerificationNeeded,
  verifyEmailWithOTP,
  sendOpt,
  verifyEmailForForgotPassword,
  resetPassword,
  removeAllBubbles,
  fetchCurrencies,
  fetchCountries,
  sendPDF,
  sendOTP,
  contactSales,
  requestJoin,
  sendSecondaryEmailOTP,
  verifyOTP,
  secondaryEmailverifyOTP,
  submitContact,
  fetchIsdCodes,
  submitKYCForm,
  storeUserDetails,
  // getOccupationList,
  getTradingAccountDetails,
  submitPoi,
  submitPoa,
  fetchCompanyReports,
  uploadBanner,
  verifyOtpForLogin,
  resendLoginOtp,
  submitDocuments
}

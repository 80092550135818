<template>
  <div class="bg-center rounded-lg border bg-no-repeat border-gray-300 bg-cover">
    <video
      class="w-14 h-14"
      :controls="controls"
      preload="metadata"
    >
      <source :src="src + '#t=10'">
    </video>
  </div>
</template>

<script>
export default {
  name: 'SquareVideo',
  props: {
    src: { type: String, default: '' },
    controls: { type: Boolean, default: true }
  }
}
</script>

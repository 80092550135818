<template>
  <div
    class="flex justify-center"
    :class="!noBorder ? 'hover:bg-custom-purple-50 dark:hover:bg-custom-purple-450 dark:bg-custom-purple-400 border-t dark:border-custom-purple-300 rounded-b-xl' : ''"
  >
    <BaseButton
      class="text-sm font-extrabold"
      :class="[
        locked ? 'text-red-500 dark:text-red-500' : 'text-custom-lightPurple-400 dark:text-custom-purple-100',
        margin,
        customClass
      ]"
      :locked="locked"
      no-shadow
      @click="$emit('click', $event)"
    >
      <slot />
    </BaseButton>
  </div>
</template>

<script>
export default {
  name: 'WidgetFooterButton',
  props: {
    locked: { type: Boolean, default: false },
    margin: { type: String, default: 'my-3' },
    noBorder:{ type: Boolean, default: false },
    customClass: { type: String, default: ' my-3' }
  },
  emits: ['click']
}
</script>

import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/advertises`

const fetchAll = async () => get(`${url}/fetch`)
const addAdvertiser = async (paylaod) => post(`${url}/add-advertiser`, paylaod)
const editAdvertiser = async (paylaod) => post(`${url}/${paylaod.id}/edit-advertiser`, paylaod)
const deleteAdvertiser = async (id) => post(`${url}/${id}/delete-advertiser`)

const addAdvertise = async (paylaod) => post(`${url}/${paylaod.advertiserId}/add-advertise`, paylaod)
const editAdvertise = async (paylaod) => post(`${url}/${paylaod.id}/edit-advertise`, paylaod)
const deleteAdvertise = async (id) => post(`${url}/${id}/delete-advertise`)
const editAdvertiseRecord = async (paylaod) => post(`${url}/${paylaod.id}/edit-advertise-record`, paylaod)
export default {
  fetchAll,
  addAdvertiser,
  editAdvertiser,
  deleteAdvertiser,
  addAdvertise,
  editAdvertise,
  deleteAdvertise,
  editAdvertiseRecord
}

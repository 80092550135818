import { get, post, put, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/allowed-routes`
const fetchAll = async () => get(`${url}`)

const createAllowedRoute = async (data) => post(`${url}/create`, data)
const editAllowedRoute = async (data) => put(`${url}/${data.id}/edit`, data)
const deleteAllowedRoute = async (id) => destroy(`${url}/${id}/delete`)

export default {
  fetchAll,
  createAllowedRoute,
  editAllowedRoute,
  deleteAllowedRoute
}

<template>
  <div class="flex w-full mb-4 mt-2 items-center justify-center bg-grey-lighter">
    <label
      v-if="url === null || url === ''"
      class="w-full flex flex-col items-center px-4 py-4 rounded border-custom-purple-300 text-custom-purple-300 border-dashed border-2"
    >
      <FontAwesomeIcon
        icon="image"
        class="text-4xl"
      />
      <span class="mt-2 text-base leading-normal">
        {{ $t('Upload image here') }}
      </span>
      <input
        type="file"
        accept="image/*"
        multiple="multiple"
        class="hidden"
        @change="$emit('on-image-change', $event)"
      >
      <span class="my-1 text-xs dark:text-purple-100">
        *Accepted file formats: jpg, png, jpeg, gif, tiff, svg, bmp - max 10 MB size.
      </span>
    </label>
    <label
      v-else
      id="preview"
    >
      <img
        alt="Preview failed"
        :src="url"
      >
    </label>
  </div>
</template>
<script>
export default {
  name: 'BaseImageInput',
  props: {
    url: { type: String, default: null },
    status: { type: Boolean, default: false }
  },
  emits: [
    'on-image-change'
  ]
}
</script>

<template>
  <div
    class="
      relative
      bg-custom-blue-500
      dark:bg-custom-purple-800
      p-2
      rounded-xl
      my-4
      text-blue-600
      dark:text-custom-purple-200
      border-2 border-blue-500 dark:border-custom-purple-400
      flex
      flex-col
      items-center
      text-xs
      text-center
      justify-center
    "
  >
    <Icon
      name="info-circle"
      class="text-4xl text-blue-500 dark:text-custom-purple-200 mb-2"
    />
    <div class="flex flex-col items-center">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoMessage'
}
</script>

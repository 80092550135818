<template>
  <div
    class="relative"
    :class="isMobile ? '' : ''"
  >
    <ModalContainer
      v-if="showSearch"
      full-size
      hide-close
      no-margin-top
      class=" mt-0"
      :padding="false"
    >
      <div class="p-4 dark:bg-custom-purple-500 text-custom-purple-500 dark:text-custom-purple-100 flex sm:hidden justify-between items-center">
        <Icon
          name="left-arrow-alt"
          class="text-2xl  cursor-pointer"
          @click="$emit('toggle-search-box')"
        />
      </div>
      <div class="relative p-4 sm:p-0">
        <div
          class="text-black cursor-pointer z-20 absolute top-0 inset-y-0 flex items-center"
        >
          <Icon
            name="search"
            class="absolute sm:left-3 left-5 cursor-pointer text-xl sm:text-lg dark:text-custom-purple-100"
            @click="$emit('toggle-search-box')"
          />
        </div>
        <input
          ref="searchTop"
          :value="modelValue"
          :placeholder="$t('Search')"
          class="p-2 pl-12 sm:pl-9 w-full lg:w-80 rounded-2xl sm:rounded-t-2xl border focus:outline-none dark:text-custom-purple-100 dark:border-custom-purple-300 dark:placeholder-custom-purple-200"
          :class="isLoggedIn ? 'dark:bg-custom-purple-400' : 'dark:bg-custom-purple-500'"
          @input="$emit('search', $event.target.value)"
          @keydown.enter="$emit('enter')"
        >
      </div>
      <div
        v-if="showResults"
        class="w-full flex flex-col max-h-64 overflow-hidden overflow-y-auto"
      >
        <div
          v-if="loadingCompanies"
          class="flex justify-center py-4"
        >
          <CircleLoader
            small
          />
        </div>
        <ul v-else>
          <!-- <CompanyListItem
                v-for="company in companies"
                :key="company.short_name"
                :company="company"
                @click="$emit('go-to-company', $event)"
              /> -->
          <li
            v-for="tag in tags"
            :key="tag.id"
            class="w-full cursor-pointer py-3 px-5 border-b border-gray-200 dark:border-custom-purple-300 dark:text-custom-purple-100 hover:bg-custom-purple-50 dark:hover:bg-custom-purple-450"
            @click="$emit('click', tag)"
          >
            <!-- <span>{{ tag.label }}</span>
            <span
              v-if="tag.type === 'company'"
              class="text-custom-purple-400 dark:text-custom-purple-100 text-xs ml-2"
            >
              - Company
            </span>
            <span
              v-if="tag.type === 'stock_user' || tag.type === 'user'"
              class="text-custom-purple-400 dark:text-custom-purple-100 text-xs ml-2"
            >
              - User
            </span> -->
            <div class="flex items-center cursor-pointer">
              <div class="text-2xl text-custom-purple-500 dark:text-custom-purple-100 mr-4">
                <div v-if="tag.type ==='user' || tag.type ==='stock_user'">
                  @
                </div>
                <div v-else-if="tag.type ==='company'">
                  $
                </div>
                <div v-else-if="tag.type ==='exchange'">
                  €
                </div>
                <div v-else-if="tag.type ==='list'">
                  #
                </div>
                <div v-else>
                  #
                </div>
              </div>
              <div>
                <p class="text-md font-semibold text-custom-purple-500 dark:text-custom-purple-100">
                  {{ tag.label?.length > 31 ? tag.label.slice(1, 31) + '...' : tag.label.slice(1) }}
                </p>
                <p
                  v-if="tag.followers_count"
                  class="text-xs text-gray-500 dark:text-custom-purple-200"
                >
                  {{ tag.followers_count }} followers
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        v-if="!showResults"
        class="pb-4"
      />
    </ModalContainer>
    <div v-else-if="isFiltration" />
    <div
      v-else
      class="relative cursor-pointer"
    >
      <div
        class="cursor-pointer bg-white flex border rounded-full text-custom-purple-500 dark:text-custom-purple-100 py-2 px-9"
        :class="[isLoggedIn ? 'dark:bg-custom-purple-400' : 'dark:bg-custom-purple-500', source === 'mobile' ? 'w-72' : 'w-60 lg:w-80']"
        @click="$emit('toggle-search-box')"
      >
        {{ $t('Search') }}
      </div>
  
      <div
        class="dark:text-custom-purple-100 text-custom-purple-500 cursor-pointer z-20 absolute top-0 inset-y-0 flex items-center"
      >
        <Icon
          name="search"
          class="absolute left-3 cursor-pointer text-xl lg:text-lg dark:text-custom-purple-100"
          @click="$emit('toggle-search-box', $event)"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
import ModalContainer from '@/modules/modal'
  
  export default {
    name: 'TopTagSearchMobile',
    components: {
        ModalContainer
    },
    props: {
      modelValue: {
        type: String,
        default: ''
      },
      loadingCompanies: {
        type: Boolean,
        default: false
      },
      showSearchInput: {
        type: Boolean,
        default: false
      },
      showSearch: {
        type: Boolean,
        default: false
      },
      showResults: {
        type: Boolean,
        default: false
      },
      isMobile: {
        type: Boolean,
        default: false
      },
      tags: {
        type: Array,
        default: () => []
      },
      isLoggedIn: {
        type: Boolean,
        default: false
      },
      source: {
        type: String,
        default: ''
      },
      isFiltration: {
        type: Boolean,
        default: false
      }
    },
  emits: [
  'toggle-search-box',
  'search',
  'click',
  'enter'
  // 'go-to-company',
  // 'go-to-screener'
  ],
    watch: {
      showSearch (newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.$refs.searchTop.focus()
          })
        }
      }
    },
    mounted() {
      console.log(this.source);
  }
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>
  
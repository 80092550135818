<template>
  <div class="lg:w-10/12">
    <form
      class="sm:pb-4"
      @submit.prevent="submit"
    >
      <LoginDetailsForm
        :email="getKycPayload('email')"
        :username="getKycPayload('username')"
        @email="setKycPayload('email', $event.target.value)"
        @username="setKycPayload('username', $event.target.value)"
      />


      <PersonalDetailsForm
        class="mt-8"
        :countries-with-states="countriesStore.countriesWithStates"
        :countries-with-isd="countriesStore.countriesWithIsd"
        :salutation="getKycPayload('salutation')"
        :first-name="getKycPayload('firstName')"
        :middle-name="getKycPayload('middleName')"
        :last-name="getKycPayload('lastName')"
        :marital-status="getKycPayload('maritalStatus')"
        :dob="getKycPayload('dob')"
        :country-of-birth="getKycPayload('countryOfBirth')"
        :dependent-no="getKycPayload('dependentNo')"
        :country-of-contact="getKycPayload('countryOfContact')"
        :contact-no="getKycPayload('contactNo')"
        @salutation="setKycPayload('salutation', $event.target.value)"
        @first-name="setKycPayload('firstName', $event.target.value)"
        @middle-name="setKycPayload('middleName', $event.target.value)"
        @last-name="setKycPayload('lastName', $event.target.value)"
        @marital-status="setKycPayload('maritalStatus', $event.target.value)"
        @dob="setKycPayload('dob', $event.target.value)"
        @country-of-birth="setKycPayload('countryOfBirth', $event.target.value), setKycPayload('countryOfContact', $event.target.value), setResidenceAddress('country', $event.target.value)"
        @dependent-no="setKycPayload('dependentNo', $event.target.value)"
        @country-of-contact="setKycPayload('countryOfContact', $event.target.value)"
        @contact-no="setKycPayload('contactNo', $event.target.value)"
      />



      <ResidenceForm
        class="mt-8"
        :countries-with-states="countriesStore.countriesWithStates"
        :street-of-residence="getKycPayload('streetOfResidence')"
        :city-of-residence="getKycPayload('cityOfResidence')"
        :postal-code-of-residence="getKycPayload('postalCodeOfResidence')"
        :country-of-residence="getKycPayload('countryOfResidence')"
        :state-of-residence="getKycPayload('stateOfResidence')"
        :resident-state-list="residentStateList"
        @street-of-residence="setResidenceAddress('street', $event.target.value)"
        @city-of-residence="setResidenceAddress('city', $event.target.value)"
        @postal-code-of-residence="setResidenceAddress('postalCode', $event.target.value)"
        @country-of-residence="setResidenceAddress('country', $event.target.value)"
        @state-of-residence="setResidenceAddress('state', $event.target.value)"
      />

      <MailForm
        class="mt-8"
        :countries-with-states="countriesStore.countriesWithStates"
        :mail-same-as-residence="getKycPayload('mailSameAsResidence')"
        :street-of-mail="getKycPayload('streetOfMail')"
        :city-of-mail="getKycPayload('cityOfMail')"
        :postal-code-of-mail="getKycPayload('postalCodeOfMail')"
        :country-of-mail="getKycPayload('countryOfMail')"
        :state-of-mail="getKycPayload('stateOfMail')"
        :mail-state-list="mailStateList"
        @mail-same-as-residence="setMailSameAsResidence"
        @street-of-mail="setKycPayload('streetOfMail', $event.target.value)"
        @city-of-mail="setKycPayload('cityOfMail', $event.target.value)"
        @postal-code-of-mail="setKycPayload('postalCodeOfMail', $event.target.value)"
        @country-of-mail="setKycPayload('countryOfMail', $event.target.value)"
        @state-of-mail="setKycPayload('stateOfMail', $event.target.value)"
      />

      <IdentificationForm
        class="mt-8"
        :theme="usersStore.activeTheme"
        :citizenship="getKycPayload('citizenship')"
        :issuing-state="getKycPayload('issuingState')"
        :issuing-rta="getKycPayload('issuingRta')"
        :citizen-medi-card-color="getKycPayload('citizenMediCardColor')"
        :citizen-medi-care-ref-no="getKycPayload('citizenMediCareRefNo')"
        :issuing-country="getKycPayload('issuingCountry')"
        :issuing-state-list="issuingStateList"
        :countries-with-states="countriesStore.countriesWithStates"
        :issued-document-type-list="issuedDocumentTypeList"
        :issued-document-type="getKycPayload('issuedDocumentType')"
        :issued-document-number="getKycPayload('issuedDocumentNumber')"
        :check-citizen-exp-date="getKycPayload('checkCitizenExpDate')"
        :citizen-exp-date="getKycPayload('citizenExpDate')"
        :open-citizen-exp-date="getKycPayload('openCitizenExpDate')"
        :selected-document-type="selectedDocumentType"
        :tax-country="getKycPayload('taxCountry')"
        @citizenship="setKycPayload('citizenship', $event.target.value), setKycPayload('issuingCountry', $event.target.value)"
        @issuing-country="setKycPayload('issuingCountry', $event.target.value)"
        @issued-document-type="setKycPayload('issuedDocumentType', $event.target.value)"
        @issued-document-number="setKycPayload('issuedDocumentNumber', $event.target.value)"
        @issuing-state="setKycPayload('issuingState', $event.target.value)"
        @issuing-rta="setKycPayload('issuingRta', $event.target.value)"
        @citizen-medi-card-color="setKycPayload('citizenMediCardColor', $event.target.value)"
        @citizen-medi-care-ref-no="setKycPayload('citizenMediCareRefNo', $event.target.value)"
        @citizen-exp-date="setCitizenExpDate"
        @toggle-citizen-exp-date="toggleCitizenExpDatePicker"
        @toggle-open-citizen-exp-date="toggleOpenCitizenExpDatePicker"
        @tax-country="setKycPayload('taxCountry', $event.target.value)"
      />



      <EmployerDetailsForm
        :ref="'employerDiv'"
        class="mt-8"
        :countries-with-states="countriesStore.countriesWithStates"
        :employer-state-list="employerStateList"
        :employ-type="getKycPayload('employType')"
        :occupation-list="getKycPayload('occupationList')"
        :occupation="getKycPayload('occupation')"
        :employer="getKycPayload('employer')"
        :employer-business="getKycPayload('employerBusiness')"
        :employer-street="getKycPayload('employerStreet')"
        :employer-city="getKycPayload('employerCity')"
        :employer-state="getKycPayload('employerState')"
        :employer-postal="getKycPayload('employerPostal')"
        :employer-country="getKycPayload('employerCountry')"
        :emp-address-same-as-residence="getKycPayload('empAddressSameAsResidence')"
        @occupation="setKycPayload('occupation', $event)"
        @employ-type="setKycPayload('employType', $event.target.value)"
        @employer="setKycPayload('employer', $event.target.value)"
        @employer-business="setKycPayload('employerBusiness', $event)"
        @employer-street="setKycPayload('employerStreet', $event.target.value)"
        @employer-city="setKycPayload('employerCity', $event.target.value)"
        @employer-state="setKycPayload('employerState', $event.target.value)"
        @employer-postal="setKycPayload('employerPostal', $event.target.value)"
        @employer-country="setKycPayload('employerCountry', $event.target.value)"
        @emp-address-same-as-residence="setEmpAddressSameAsResidence"
      />


      <FinancialDetailsForm
        class="mt-8"
        :net-worth-list="netWorthList"
        :liquid-net-worth-list="liquidNetWorthList"
        :annual-net-income-list="annualNetIncomeList"
        :net-worth="getKycPayload('netWorth')"
        :liquid-net-worth="getKycPayload('liquidNetWorth')"
        :annual-net-income="getKycPayload('annualNetIncome')"
        :base-currency="getKycPayload('baseCurrency')"
        @net-worth="setKycPayload('netWorth', $event.target.value)"
        @liquid-net-worth="setKycPayload('liquidNetWorth', $event.target.value)"
        @annual-net-income="setKycPayload('annualNetIncome', $event.target.value)"
      />

      <SourceOfWealthForm
        class="mt-8"
        :employ-type="getKycPayload('employType')"
        :source-wealth-type="getKycPayload('sourceWealthType')"
        :custom-source-of-income="getKycPayload('customSourceOfIncome')"
        :used-for-funds="getKycPayload('usedForFunds')"
        :income-source-percent="getKycPayload('incomeSourcePercent')"
        @source-wealth-type="setKycPayload('sourceWealthType', $event.target.value)"
        @custom-source-of-income="setKycPayload('customSourceOfIncome', $event.target.value)"
        @used-for-funds="setKycPayload('usedForFunds', !usedForFunds)"
        @income-source-percent="setKycPayload('incomeSourcePercent', $event.target.value)"
      />


      <InvestmentDetailsForm
        class="mt-8"
        :investment-objective="getKycPayload('investmentObjective')"
        :asset-class="getKycPayload('assetClass')"
        :knowledge-level="getKycPayload('knowledgeLevel')"
        :trades-per-year="getKycPayload('tradesPerYear')"
        :years-trading="getKycPayload('yearsTrading')"
        @investment-objective="setKycPayload('investmentObjective', $event.target.value)"
        @asset-class="setKycPayload('assetClass', $event.target.value)"
        @knowledge-level="setKycPayload('knowledgeLevel', $event.target.value)"
        @trades-per-year="setKycPayload('tradesPerYear', $event.target.value)"
        @years-trading="setKycPayload('yearsTrading', $event.target.value)"
      />

      <div class="flex w-full items-center justify-end">
        <ActiveButton
          class="bg-custom-orange-400 h-12 rounded-xl disabled:opacity-50 mt-8"
          type="submit"
        >
          <span class="text-white">
            {{ $t('Continue process') }}
          </span>
        </ActiveButton>
      </div>
    </form>

    <ModalContainer
      v-if="showInvalidCountryModal"
      only-back-with-toggle
      @toggle=" showInvalidCountryModal = false"
      @on-back-button=" showInvalidCountryModal = false"
    >
      <InvalidCountryAlert
        @close="showInvalidCountryModal = false"
        @back-to-profile="$router.push({ name: 'myEucapsSettings' })"
      />
    </ModalContainer>
  </div>
</template>

<script>
import LoginDetailsForm from './_components/LoginDetailsForm'
import PersonalDetailsForm from './_components/PersonalDetailsForm'
import ResidenceForm from './_components/ResidenceForm'
import MailForm from './_components/MailForm'
import IdentificationForm from './_components/IdentificationForm'
import EmployerDetailsForm from './_components/EmployerDetailsForm'
import FinancialDetailsForm from './_components/FinancialDetailsForm'
import SourceOfWealthForm from './_components/SourceOfWealthForm'
import InvestmentDetailsForm from './_components/InvestmentDetailsForm'
import InvalidCountryAlert from './_components/InvalidCountryAlert'
import stringHandler from '@/js/helpers/string'
import i18n from '@/js/language'
import ModalContainer from '@/modules/modal'
import { useOnboardingsStore } from '../../stores/onboarding'
import { useCompaniesStore } from '../../stores/companies'
import { useUsersStore } from '../../stores/users'
import { useVerifysStore } from '../../stores/verify'
import { useAppsStore } from '../../stores/app'
import { useCountriesStore } from '@/stores/countries'
import { useFinancialsStore } from '@/stores/financials'
import { useSnackbarStore } from '@/stores/snackbar'
import occupationsJson from '@/assets/json/occupations.json'
import { differenceInYears, isPast } from 'date-fns'

export default {
    name: 'UserKYCFormModule',
    components: {
        LoginDetailsForm,
        PersonalDetailsForm,
        ResidenceForm,
        MailForm,
        EmployerDetailsForm,
        IdentificationForm,
        FinancialDetailsForm,
        SourceOfWealthForm,
        InvestmentDetailsForm,
        InvalidCountryAlert,
        ModalContainer
    },
    emits: [
        'continue'
    ],
    setup () {
        const onboardingsStore = useOnboardingsStore()
        const companiesStore = useCompaniesStore()
        const usersStore = useUsersStore()
        const verifyStore = useVerifysStore()
        const appsStore = useAppsStore()
        const countriesStore = useCountriesStore()
        const financialsStore = useFinancialsStore()
        const snackBarStore = useSnackbarStore()

        return { onboardingsStore, companiesStore, usersStore, verifyStore, appsStore, countriesStore, financialsStore, snackBarStore }
    },
    data () {
        return {
            showProgessModal: false,
            showInvalidCountryModal: false
        }
    },
    computed: {

        affiliationStateList () {
            let result = [];

            if (this.getKycPayload('affiliationCountry') && this.getKycPayload('affiliationCountry') !== '') {
                let selectedCountry = this.countriesStore.countriesWithStates.find(country => {
                    return country.ibkr_code === this.getKycPayload('affiliationCountry')
                })

                if (selectedCountry && selectedCountry.states) {
                    result = selectedCountry.states
                }
            }
            return result;
        },

        mailStateList () {
            let result = [];


            if (this.getKycPayload('countryOfMail') && this.getKycPayload('countryOfMail') !== '' && this.countriesStore.countriesWithStates) {
                let selectedCountry = this.countriesStore.countriesWithStates.find(country => {
                    return country.ibkr_code === this.getKycPayload('countryOfMail')
                })

                if (selectedCountry && selectedCountry.states) {
                    result = selectedCountry.states
                }

            }
            return result;
        },
        residentStateList () {
            let result = [];


            if (this.getKycPayload('countryOfResidence') && this.getKycPayload('countryOfResidence') !== '') {
                let selectedCountry = this.countriesStore.countriesWithStates.find(country => {
                    return country.ibkr_code === this.getKycPayload('countryOfResidence')
                })


                if (selectedCountry && selectedCountry.states) {
                    result = selectedCountry.states
                }
            }

            return result;
        },
        issuedDocumentTypeList () {
            let data = [];
            if (this.getKycPayload('issuingCountry') === 'USA') {
                data = [{
                    code: 'SSN',
                    example: '(E.g. 123-45-6789)',
                    title: 'Please enter a valid SSN (Social Security Number): Format should be 123-45-6789',
                    name: i18n.global.t('Social Security Number (SSN)'),
                    pattern: '^(?!\b(\\d)\x01+(\\d)\x01+(\\d)\x01+\b)(?!123456789|219099999|078051120)(?!666|000)\\d{3}(?!00)\\d{2}(?!0{4})\\d{4}$'
                }]
            } else if (this.getKycPayload('issuingCountry') === 'IND') {
                data = [{
                    example: '(E.g. ABCDE1234F)',
                    title: 'Please enter a valid PAN number: Five uppercase letters followed by four digits and one uppercase letter',
                    code: 'PANNumber',
                    name: i18n.global.t('Permanent Account Number (PAN)'),
                    pattern: '^[A-Z]{5}\\d{4}[A-Z]{1}$'
                }]
            } else if (this.getKycPayload('issuingCountry') === 'CAN') {
                data = [{
                    example: '(E.g. 123456789)',
                    title: 'Please enter a valid SIN (Social Insurance Number): Should consist of 9 digits',
                    code: 'SIN',
                    name: i18n.global.t('Social Insurance Number (SIN)'),
                    pattern: '^\\d{9}$'
                }]
            } else if (this.getKycPayload('issuingCountry') === 'AUS') {
                data = [{
                    example: '(E.g. 123456789)',
                    title: 'Please enter a valid Tax File Number: Should consist of either 8 or 9 digits',
                    code: 'NationalCard',
                    name: i18n.global.t('Tax File Number'),
                    pattern: '^(\\d{8}|\\d{9})$'
                },
                {
                    example: '',
                    title: 'Please enter a valid Australian Driver’s License: Should not exceed 64 characters',
                    code: 'DriversLicense',
                    name: i18n.global.t('Australian Driver’s License'),
                    pattern: '^.{0,64}$'
                },
                {
                    example: '',
                    title: '',
                    code: 'MedicareCard',
                    name: i18n.global.t('Medicare Card'),
                    pattern: ''
                },



                ]
            } else if (this.getKycPayload('issuingCountry') === 'ARG') {
                data = [{
                    example: '(E.g. 12345678)',
                    title: 'Please enter a valid Documento Nacional Identidad (DNI) number: Should consist of 8 digits',
                    code: 'NationalCard',
                    name: i18n.global.t('Documento Nacional Identidad Number'),
                    pattern: '^\\d{8}$'
                }]
            } else if (this.getKycPayload('issuingCountry') === 'BRA') {
                data = [{
                    example: '(E.g. 12345678901)',
                    title: 'Please enter a valid Cadastro de Pessoas Fisicas (CPF) number: Should consist of 11 digits',
                    code: 'NationalCard',
                    name: i18n.global.t('Cadastro de Pessoas Fisicas'),
                    pattern: '^\\d{11}$',

                }]
            } else if (this.getKycPayload('issuingCountry') === 'CHN') {
                data = [{
                    example: '(E.g. 12345678901234567X)',
                    title: 'Please enter a valid Resident Identity Card Number: Should consist of 18 digits (17 digits followed by a digit or "X")',
                    code: 'NationalCard',
                    name: i18n.global.t('Resident Identity Card Number'),
                    pattern: '^\\d{17}(\\d|X)$'
                }]
            } else if (this.getKycPayload('issuingCountry') === 'DNK') {
                data = [{
                    example: '(E.g. 1234567890)',
                    title: 'Please enter a valid Det Centrale Personregister (CPR) number: Should consist of 10 digits',
                    code: 'NationalCard',
                    name: i18n.global.t('Det Centrale Personregister'),
                    pattern: '^\\d{10}$'
                }]
            } else if (this.getKycPayload('issuingCountry') === 'ESP') {
                data = [{
                    example: '(E.g. 12345678A)',
                    title: 'Please enter a valid Numero de Identifacion Fiscal (NIF): Should consist of 8 digits followed by one uppercase letter',
                    code: 'NationalCard',
                    name: i18n.global.t('Numero de Identifacion Fiscal'),
                    pattern: '^\\d{8}[A-Z]$'
                }]
            } else if (this.getKycPayload('issuingCountry') === 'FRA') {
                data = [{
                    example: '(E.g. 123456789012345)',
                    title: 'Please enter a valid Institut National Etudes Economiques number: Should consist of 15 digits',
                    code: 'NationalCard',
                    name: i18n.global.t('Institut National Etudes Economiques'),
                    pattern: '^\\d{15}$'
                },
                {
                    example: '(E.g. 1234A12A34567)',
                    title: 'Please enter a valid French National National Id: Should consist of 4 digits, followed by 3 characters (either uppercase letters or digits), followed by 5 digits',
                    code: 'NationalCard',
                    name: i18n.global.t('French National National Id'),
                    pattern: '^\\d{4}([A-Z]|\\d){3}\\d{5}$'
                }]

            } else if (this.getKycPayload('issuingCountry') === 'ITA') {
                data = [{
                    example: '(E.g. AB1234567 or 1234567AB or AB12345AB)',
                    title: 'Please enter a valid Italian National Id: Should consist of 2 uppercase letters followed by 7 digits, or 7 digits followed by 2 uppercase letters, or 2 uppercase letters followed by 5 digits followed by 2 uppercase letters',
                    code: 'NationalCard',
                    name: i18n.global.t('Italian National Id'),
                    pattern: '^([A-Z]{2}\\d{7}|\\d{7}[A-Z]{2}|[A-Z]{2}\\d{5}[A-Z]{2})$'
                },
                {
                    example: '(E.g. ABCDEF12G34H567I)',
                    title: 'Please enter a valid Codice Fiscale: Should consist of 6 uppercase letters, followed by 2 digits, followed by an uppercase letter, followed by 2 digits, followed by an uppercase letter, followed by 3 digits, followed by an uppercase letter',
                    code: 'NationalCard',
                    name: i18n.global.t('Codice Fiscale'),
                    pattern: '^[A-Z]{6}\\d{2}[A-Z]\\d{2}[A-Z]\\d{3}[A-Z]$'
                }]


            } else if (this.getKycPayload('issuingCountry') === 'MEX') {
                data = [{
                    example: '(E.g. ABCD123456ABCDEF1234)',
                    title: 'Please enter a valid Clave Unica Registro de Poblacion (CURP): Should consist of 4 uppercase letters followed by 6 digits, followed by 6 uppercase letters, followed by 2 digits',
                    code: 'NationalCard',
                    name: i18n.global.t('Clave Unica Registro de Poblacion'),
                    pattern: '^[A-Z]{4}\\d{6}[A-Z]{6}\\d{2}$'
                }]


            } else if (this.getKycPayload('issuingCountry') === 'MYZ') {
                data = [{
                    example: '(E.g. 123456789012)',
                    title: 'Please enter a valid MYS National Registration Identity Card: Should consist of 12 digits',
                    code: 'NationalCard',
                    name: i18n.global.t('MYS National Registration Identity Card'),
                    pattern: '^\\d{12}$'
                }]


            } else if (this.getKycPayload('issuingCountry') === 'RUS') {
                data = [{
                    example: '(E.g. 1234567890)',
                    title: 'Please enter a valid Transborder Passport Russian Federation: Should consist of 10 digits',
                    code: 'NationalCard',
                    name: i18n.global.t('Transborder Passport Russian Federation'),
                    pattern: '^\\d{10}$'
                },
                {
                    example: '(E.g. 123456789)',
                    title: 'Please enter a valid Russian International Passport Number: Should consist of 9 digits',
                    code: 'NationalCard',
                    name: i18n.global.t('Russian International Passport Number'),
                    pattern: '^\\d{9}$'
                }]


            } else if (this.getKycPayload('issuingCountry') === 'SGP') {
                data = [{
                    example: '(E.g. A1234567Z)',
                    title: 'Please enter a valid National Registration Identity Card: Should consist of an uppercase letter followed by 7 digits and ending with an uppercase letter',
                    code: 'NationalCard',
                    name: i18n.global.t('National Registration Identity Card'),
                    pattern: '^[A-Z]\\d{7}[A-Z]$'
                }]


            } else if (this.getKycPayload('issuingCountry') === 'SWE') {
                data = [{
                    example: '(E.g. 1234567890 or 123456789012)',
                    title: 'Please enter a valid Personal Identification Number: Should consist of either 10 or 12 digits',
                    code: 'NationalCard',
                    name: i18n.global.t('Personal Identification Number'),
                    pattern: '^(\\d{10}|\\d{12})$'
                }]

            } else if (this.getKycPayload('issuingCountry') === 'TUR') {
                data = [{
                    example: '(E.g. 12345678901)',
                    title: 'Please enter a valid Kimlik Number: Should consist of 11 digits',
                    code: 'NationalCard',
                    name: i18n.global.t('Kimlik Number'),
                    pattern: '^\\d{11}$'
                }]


            } else if (this.getKycPayload('issuingCountry') === 'ZAF') {
                data = [{
                    example: '(E.g. 1234567890123)',
                    title: 'Please enter a valid South African Identification Number: Should consist of 13 digits',
                    code: 'NationalCard',
                    name: i18n.global.t('South African Identification Number'),
                    pattern: '^\\d{13}$'
                }]

            } else if (this.getKycPayload('issuingCountry') === 'NZL') {
                data = [{
                    example: '(E.g. AB123456)',
                    title: 'Please enter a valid New Zealand Driver’s License Number: Should consist of 2 uppercase letters followed by 6 digits',
                    code: 'DriversLicense',
                    name: i18n.global.t('New Zealand Driver’s License Number'),
                    pattern: '^[A-Z]{2}\\d{6}$'
                }]


            } else {
                data = [
                    {
                        example: '',
                        title: 'Please enter a valid Drivers License Number',
                        code: 'DriversLicense',
                        name: i18n.global.t('Drivers License Number'),
                        pattern: ''
                    },
                    {
                        example: '',
                        title: 'Please enter a valid Passport Number',
                        code: 'Passport',
                        name: i18n.global.t('Passport Number'),
                        pattern: ''
                    },
                    {
                        example: '',
                        title: 'Please enter a valid Alien Card Number',
                        code: 'AlienCard',
                        name: i18n.global.t('Alien Card Number'),
                        pattern: ''
                    }
                ]

            }


            return data;
        },
        selectedDocumentType () {
            if (this.issuedDocumentTypeList.length > 0) {
                let issuedDocumentTypeData = this.issuedDocumentTypeList.find(documentType => {

                    return (documentType.code === this.getKycPayload('issuedDocumentType'))
                })

                if (issuedDocumentTypeData) {
                    return issuedDocumentTypeData
                }
            }

            return {}
        },
        issuingStateList () {
            let result = [];


            if (this.getKycPayload('issuingCountry') && this.getKycPayload('issuingCountry') !== '') {
                let selectedCountry = this.countriesStore.countriesWithStates.find(country => {
                    return country.ibkr_code === this.getKycPayload('issuingCountry')
                })
                if (selectedCountry && selectedCountry.states) {
                    result = selectedCountry.states
                }
            }
            return result;
        },
        employerStateList () {
            let result = [];

            if (this.getKycPayload('employerCountry') && this.getKycPayload('employerCountry') !== '') {
                let selectedCountry = this.countriesStore.countriesWithStates.find(country => {
                    return country.ibkr_code === this.getKycPayload('employerCountry')
                })

                if (selectedCountry && selectedCountry.states) {
                    result = selectedCountry.states
                }
            }
            return result;
        },

        netWorthList () {
            let currencyNetWorth = []

            //    console.log(' this.financialsStore.financialRanges', this.financialsStore.financialRanges);
            if (this.getKycPayload('baseCurrency') !== '' && this.financialsStore.financialRanges) {
                let allNetWorth = this.financialsStore.financialRanges.find(financialCategoryRange => {
                    return (financialCategoryRange.code === 'net_worth')
                });
                currencyNetWorth = this.getFinancialRangeList(allNetWorth)
            }
            //   console.log(currencyNetWorth);
            return currencyNetWorth
        },
        liquidNetWorthList () {
            let currencyLiquidNetWorth = []

            if (this.getKycPayload('baseCurrency') !== '' && this.financialsStore.financialRanges) {
                let allLiquidNetWorth = this.financialsStore.financialRanges.find(financialCategoryRange => {
                    return (financialCategoryRange.code === 'net_worth_liquid')
                });
                currencyLiquidNetWorth = this.getFinancialRangeList(allLiquidNetWorth)
            }

            return currencyLiquidNetWorth
        },
        annualNetIncomeList () {
            let currencyAnnualNetIncome = []

            if (this.getKycPayload('baseCurrency') !== '' && this.financialsStore.financialRanges) {
                let allAnnualNetIncome = this.financialsStore.financialRanges.find(financialCategoryRange => {
                    return (financialCategoryRange.code === 'annual_net_income')
                });

                currencyAnnualNetIncome = this.getFinancialRangeList(allAnnualNetIncome)
            }

            return currencyAnnualNetIncome
        },

    },
    async beforeMount () {
        await this.usersStore.fetch()
      
        if (this.usersStore.user.trading_account_id) {
            this.$router.push({ name: 'myEucapsSettings' })
            return
        }

        if (localStorage.kycPayload) {
            this.onboardingsStore.kycPayload = JSON.parse(localStorage.kycPayload)
        } else {

            this.setKycPayload('email', this.usersStore.user.email)
            this.setKycPayload('username', this.usersStore.user.profile.username)
            this.setKycPayload('firstName', this.usersStore.user.first_name)
            this.setKycPayload('lastname', this.usersStore.user.last_name)
            this.setKycPayload('contactNo', this.usersStore.user.profile.phone)
        }

        this.setKycPayload('occupationList', occupationsJson)

        this.countriesStore.fetchWithStates()
        this.countriesStore.fetchWithIsd()
        this.financialsStore.fetchFinancialRanges()

        console.log('in before mounted  this.onboardingsStore.kycPayload',  this.onboardingsStore.kycPayload)
    },
    methods: {
        getKycPayload (key) {
            if (!this.onboardingsStore || !this.onboardingsStore.kycPayload) {
                return ''
            }

            return this.onboardingsStore.kycPayload[key]
        },
        setKycPayload (key, value) {
            console.log("SET KYCPAYLOAD OMG", key)
            console.log("SET KYCPAYLOAD OMG", value)
            this.onboardingsStore.kycPayload = {
                ...this.onboardingsStore.kycPayload,
                [key]: value
            }

            localStorage.kycPayload = JSON.stringify(this.onboardingsStore.kycPayload)
        },
        setMailSameAsResidence () {
            this.onboardingsStore.kycPayload.mailSameAsResidence = !this.onboardingsStore.kycPayload.mailSameAsResidence

            if (this.onboardingsStore.kycPayload.mailSameAsResidence) {
                this.setKycPayload('streetOfMail', this.getKycPayload('streetOfResidence'))
                this.setKycPayload('cityOfMail', this.getKycPayload('cityOfResidence'))
                this.setKycPayload('countryOfMail', this.getKycPayload('countryOfResidence'))
                this.setKycPayload('stateOfMail', this.getKycPayload('stateOfResidence'))
                this.setKycPayload('postalCodeOfMail', this.getKycPayload('postalCodeOfResidence'))
            }

        },
        setEmpAddressSameAsResidence () {

            this.setKycPayload('empAddressSameAsResidence', !this.getKycPayload('empAddressSameAsResidence'))

            if ( this.getKycPayload('employType')==='SELFEMPLOYED' && this.getKycPayload('empAddressSameAsResidence')) {
                this.setKycPayload('employerStreet', this.getKycPayload('streetOfResidence'))
                this.setKycPayload('employerCity',this.getKycPayload('cityOfResidence'))
                this.setKycPayload('employerState',this.getKycPayload('stateOfResidence'))
                this.setKycPayload('employerPostal',this.getKycPayload('postalCodeOfResidence'))
                this.setKycPayload('employerCountry',this.getKycPayload('countryOfResidence'))
            }

        },
        setCitizenExpDate (date) {

            this.setKycPayload('citizenExpDate', date)
            this.setKycPayload('openCitizenExpDate', false)
        },
        close () {
            this.showProgessModal = false
        },
        async submit () {

            if (this.hasInvalidCountry()) {
                this.showInvalidCountryModal = true

                return
            } else if (differenceInYears(new Date(), new Date(this.getKycPayload('dob'))) < 18) {
                this.snackBarStore.snack({
                    type: 'warning',
                    text: 'Date of birth should be greater or equal to 18',
                    show: true,
                    autoclose: true
                })
                return
            } else if (this.getKycPayload('streetOfResidence') === 'PO Box') {

                this.snackBarStore.snack({
                    type: 'warning',
                    text: 'PO Box not accepted as residential address',
                    show: true,
                    autoclose: true
                })
                return
            } else if (this.getKycPayload('mailSameAsResidence') && this.getKycPayload('mailSameAsResidence') === true && this.getKycPayload('streetOfMail') === 'PO Box') {
                this.snackBarStore.snack({
                    type: 'warning',
                    text: 'PO Box not accepted as mail address',
                    show: true,
                    autoclose: true
                })
                return
            } else if (this.getKycPayload('issuedDocumentHasExpiry') && isPast(this.getKycPayload('issuedDocumentExpiryDate'))) {
                this.snackBarStore.snack({
                    type: 'warning',
                    text: 'Looks like your citizenship document is been expired',
                    show: true,
                    autoclose: true
                })
                return
            }

            let letEmployType =this.getKycPayload('employType')
            if(letEmployType === 'EMPLOYED' || letEmployType === 'SELFEMPLOYED'){

                if(!this.getKycPayload('occupation')){
                    this.snackBarStore.snack({
                    type: 'warning',
                    text: 'You need to Choose Occupation',
                    show: true,
                    autoclose: true
                    })

                    this.$refs.employerDiv.$refs.occupationDiv.scrollIntoView()
                    this.$refs.employerDiv.$refs.occupationDiv.focus()
                    this.$refs.employerDiv.$refs.occupationSelect.activate()

                    return

                }
                
                if(!this.getKycPayload('employerBusiness')){
                    this.snackBarStore.snack({
                    type: 'warning',
                    text: 'You need to Choose Employer`s Business',
                    show: true,
                    autoclose: true
                    })
                    this.$refs.employerDiv.$refs.employerBusinessDiv.scrollIntoView()
                    this.$refs.employerDiv.$refs.employerBusinessDiv.focus()
                    this.$refs.employerDiv.$refs.employerBusinessSelect.activate()

                    return
                }
            }

            if (this.getKycPayload('employType')==='SELFEMPLOYED' && this.getKycPayload('empAddressSameAsResidence')) {
                this.setKycPayload('employerStreet', this.getKycPayload('streetOfResidence'))
                this.setKycPayload('employerCity',this.getKycPayload('cityOfResidence'))
                this.setKycPayload('employerState',this.getKycPayload('stateOfResidence'))
                this.setKycPayload('employerPostal',this.getKycPayload('postalCodeOfResidence'))
                this.setKycPayload('employerCountry',this.getKycPayload('countryOfResidence'))
            }

            this.$emit('continue')
        },
        toggleCitizenExpDatePicker () {
            this.setKycPayload('checkCitizenExpDate', !this.getKycPayload('checkCitizenExpDate'))

            if (this.getKycPayload('checkCitizenExpDate') === true) {
                this.setKycPayload('openCitizenExpDate', true)
            } else {
                this.setKycPayload('citizenExpDate', '')
            }

        },
        toggleOpenCitizenExpDatePicker () {
            this.setKycPayload('openCitizenExpDate', !this.getKycPayload('this.openCitizenExpDate'))
        },
        getFinancialRangeList (data) {
            let currencyFinancialRange = []

            if (data) {

                let financialRanges = data.financial_ranges.filter(financialRange => {
                    return (this.getKycPayload('baseCurrency') === financialRange.currency)
                })

                financialRanges.map(financialRange => {

                    let label = financialRange.lower_bound.replace(' ', ',') + " - " + financialRange.upper_bound.replace(' ', ',')

                    if (stringHandler.isEmpty(financialRange.lower_bound) && !stringHandler.isEmpty(financialRange.upper_bound)) {
                        label = i18n.global.t('Below') + ' ' + financialRange.upper_bound.replace(' ', ',')

                    } else if (!stringHandler.isEmpty(financialRange.lower_bound) && stringHandler.isEmpty(financialRange.upper_bound)) {
                        label = i18n.global.t('Above') + ' ' + financialRange.lower_bound.replace(' ', ',')
                    }
                    currencyFinancialRange.push({
                        id: financialRange.id,
                        range_id: financialRange.range_id,
                        label: label
                    })
                })
                currencyFinancialRange.sort((a, b) => {
                    return a.range_id - b.range_id;
                });
            }
            return currencyFinancialRange
        },

        // currentDateTime () {
        //     let date = new Date();
        //     let formatedDate = format(date, 'dd MMMM yyyy K:mm');

        //     let timezone = "";
        //     try {
        //         // Chrome, Firefox
        //         timezone = /.*\s(.+)/.exec((date).toLocaleDateString(navigator.language, { timeZoneName: 'short' }))[1];
        //     } catch (e) {
        //         // IE, some loss in accuracy due to guessing at the abbreviation
        //         // Note: This regex adds a grouping around the open paren as a
        //         //       workaround for an IE regex parser bug
        //         timezone = (date).toTimeString().match(new RegExp("[A-Z](?!.*[\\(])", "g")).join('');
        //     }

        //     return formatedDate + ' ' + timezone
        // },


        setResidenceAddress (addressType, value) {

            this.setKycPayload(addressType + 'OfResidence', value)

            if (this.mailSameAsResidence) {
                this.setKycPayload(addressType + 'OfMail', value)
            }

            if (addressType === 'country') {
                this.setKycPayload('taxCountry', value)
                this.setKycPayload('issuingCountry', value)
                this.setKycPayload('citizenship', value)
            }

            if (addressType === 'state') {
                this.setKycPayload('issuingState', value)
            }

            if (this.issuedDocumentTypeList.length === 1) {
                this.setKycPayload('issuedDocumentType', this.issuedDocumentTypeList[0].code)
            }
        },

        hasInvalidCountry () {

            const invalidCountries = this.countriesStore.countriesWithStates.filter(country => {
                return country.ibkr_active === 0
            })

            const countries = [
                this.getKycPayload('citizenship'),
                this.getKycPayload('countryOfMail'),
                this.getKycPayload('issuingCountry'),
                this.getKycPayload('taxCountry')
            ]

            return countries.some(country => {
                return invalidCountries.some(invalidCountry => {
                    return invalidCountry.ibkr_code === country
                })
            })
        }
    }
}
</script>

import { get, post, put, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/notes`

const fetch = async (data) => get(`${url}`, data)
const create = async (data) => post(`${url}`, data)
const update = async (id, data) => put(`${url}/${id}`, data)
const remove = async (id) => destroy(`${url}/${id}`)

export default {
  fetch,
  create,
  update,
  remove
}

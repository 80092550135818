<template>
  <AvatarNameSubTitle
    class="py-1"
    :stock-user="stockUser"
    :name="stockUser.username"
    :size="24"
    text-size="text-xs"
    default-class="w-6 h-6 mr-2"
  />
</template>

<script>

export default {
  name: 'UserSearchCard',
  props: {
    stockUser: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<template>
  <div class="w-full">
    <hr class="text-xs border-0 text-custom-purple-300 dark:text-custom-purple-200 w-full">
  </div>
</template>

<script>

export default {
  name: 'DotSplitter'
}
</script>

<style lang="scss" scoped>
hr {
  height:14px;
  overflow:hidden;
}

hr:after {
  content:"............................................................................................................................................................................................................................";
  letter-spacing: 2px;
}
</style>

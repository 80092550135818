<template>
  <div v-if="notification.notifiable">
    <div>
      <Notification
        :pivot="notification.pivot"
        @remove="$emit('remove', { id: notification.id })"
      >
        <div @click="$parent.redirectProfile({ notificationId: notification.id, query: { navigate: 'tradingRef' }})">
          <div>
            {{ notification.notifiable.title }}
          </div>
          <div class="text-xs mr-6">
            <span class="font-bold">

              {{ notification.notifiable.message }}
              <div>{{ time }}</div></span>
          </div>
        </div>
      </Notification>
    </div>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationReUploadDocument',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => {} },
    time: { type: String, default: '' }
  },
emits: [
'remove'
],
}
</script>

<template>
  <div
    :class="[
      position,
      orange ? 'bg-custom-orange-400' : '',
      green ? 'bg-custom-green-100' : '',
      greenLight ? 'bg-custom-green-300' : '',
      purple ? 'bg-custom-purple-500' : '',
      yellow ? 'bg-custom-yellow-300' : '',
      purpleMedium ? 'bg-custom-purple-300' : '',
      purpleLight ? 'bg-custom-purple-200' : '',
      purpleLighter ? 'bg-custom-purple-100' : '',
      plum ? 'bg-custom-purple-500' : '',
      plumLight ? 'bg-custom-purple-400' : '',
      col ? 'col-span-'+col : '',
      row ? 'row-span-'+row : '',
    ]"
  >
    <div
      v-if="src"
      class="absolute inset-0 bg-cover bg-center"
      :style="'background-image: url(' + src + ')'"
    />
  </div>
</template>
<script>
export default {
  name: 'SquareBox',
  props: {
    position: {
      type: String,
      default: 'relative'
    },
    purpleLight: {
      type: Boolean,
      default: false
    },
    purpleLighter: {
      type: Boolean,
      default: false
    },
    purpleMedium: {
      type: Boolean,
      default: false
    },
    green: {
      type: Boolean,
      default: false
    },
    greenLight: {
      type: Boolean,
      default: false
    },
    orange: {
      type: Boolean,
      default: false
    },
    purple: {
      type: Boolean,
      default: false
    },
    yellow: {
      type: Boolean,
      default: false
    },
    plum: {
      type: Boolean,
      default: false
    },
    plumLight: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: ''
    },
    col: {
      type: String,
      default: '1'
    },
    row: {
      type: String,
      default: '1'
    }
  }
}
</script>
<style scoped>
</style>

import { get, post, downloadBlob } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/dam`

const getWithdrawableCash = async () => get(`${url}/get-withdrawable-cash`)
const withdrawFunds = async (data) => post(`${url}/withdraw-funds`, data)
const initSsoCreate = async (data) => post(`${url}/sso-create`, data)
const requestFirstWithdrawalEmail = async (data) => post(`${url}/request-first-withdrawal`, data)
const depositFunds = async (data) => post(`${url}/deposit-funds`, data)
const getWalletHistory = async (data) => get(`${url}/get-dam-wallet-transactions`, data)
const cancelWalletTransaction = async (transactionId, data) => post(`${url}/${transactionId}/cancel-transaction`, data)
const getWireInstructions = async () => get(`${process.env.VUE_APP_API_URL}/wire-instructions`)
const getInstructionSetList = async () => get(`${url}/get-instruction-name`)
const downloadReceipt = async (transactionId) => downloadBlob(`${url}/${transactionId}/pdf`)
const getAccountStatus = async () => post(`${url}/get-account-status`)

export default {
  getWithdrawableCash,
  withdrawFunds,
  initSsoCreate,
  requestFirstWithdrawalEmail,
  depositFunds,
  getWalletHistory,
  cancelWalletTransaction,
  getWireInstructions,
  getInstructionSetList,
  downloadReceipt,
  getAccountStatus
}

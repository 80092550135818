<template>
  <div
    class="flex justify-center py-4 w-full"
  >
    <CircleLoader
      small
    />
  </div>
</template>

<script>

export default {
  name: 'UpdateLoader'
}
</script>

<template>
  <img
    :class="small ? 'w-10 mb-2' : 'w-40'"
    src="/logo.svg"
  >
</template>

<script>

export default {
  name: 'EucapsLogoComponent',
  props: {
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <AvatarNameSubTitle
    :company="company"
    :name="company.name"
    text-size="text-2xs"
  />
</template>

<script>

export default {
  name: 'CompanySearchCard',
  props: {
    company: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

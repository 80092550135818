<template>
  <div class="grid grid-cols-1 sm:grid-cols-12">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'GridTwelveColumn'
}
</script>

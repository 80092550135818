<template>
  <div class="bg-white dark:bg-custom-purple-800 border-b dark:border-custom-purple-450 z-60">
    <Container
      padding="p-0"
      wide
    >
      <div class="flex w-full items-center justify-between">
        <div class="flex w-full items-center justify-between py-3">
          <div class="flex w-full items-center">
            <slot name="before-title" />
            <slot name="icon">
              <Icon
                :name="icon"
                class="text-4xl mr-2 text-custom-purple-500 dark:text-custom-purple-100"
                :class="hideTitleInMobile ? 'hidden sm:block' : ''"
              />
            </slot>
            <div class="flex flex-col sm:flex-row justify-center sm:justify-start sm:items-center">
              <Title
                class="text-2xl text-custom-purple-500 dark:text-white"
                :class="hideTitleInMobile ? 'hidden sm:block' : ''"
              >
                {{ title }}
              </Title>
              <slot name="after-title" />
            </div>
          </div>
          <slot />
        </div>
      </div>
    </Container>
  </div>
</template>

<script>

export default {
  name: 'TitleBackground',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    hideTitleInMobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div class="flex flex-col md:flex-row items-stretch mb-4 w-full">
    <div
      class="mb-4 md:mb-0 md:pr-2"
      :class="leftColumn"
    >
      <slot name="left" />
    </div>
    <div
      class="mb-4 md:mb-0 md:pl-2"
      :class="rightColumn"
    >
      <slot name="right" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'EqualHeightRow',
  props: {
    left: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
    both: { type: Boolean, default: false }
  },
  computed: {
    leftColumn () {
      if (this.both) {
        return 'w-full md:w-1/2'
      }
      if (this.left) {
        return 'w-full md:w-7/12'
      }
      if (this.right) {
        return 'w-full md:w-5/12'
      }

      return 'w-full'
    },
    rightColumn () {
      if (this.both) {
        return 'w-full md:w-1/2'
      }
      if (this.right) {
        return 'w-full md:w-7/12'
      }
      if (this.left) {
        return 'w-full md:w-5/12'
      }

      return 'w-full'
    }
  }
}
</script>

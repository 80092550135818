<template>
  <div class="bg-custom-purple-500 z-60 sticky top-0 sm:static">
    <Container
      h-auto
      wide
      padding="p-0"
    >
      <FrontHeader
        :open="open"
        :loaded="loaded"
        @toggle-navbar="toggleNavbar"
      />
    </Container>
  </div>
  <MyEucapsMenuModalContainer
    v-if="open"
    :show-my-eucaps-menu="open"
    @toggle-my-eucaps-menu="open = !open"
  />
</template>

<script>
import FrontHeader from './_components/FrontHeader'
import MyEucapsMenuModalContainer from '@/modules/myEucapsMenuModal'

export default {
  name: 'FrontHeaderModule',
  components: {
    FrontHeader,
    MyEucapsMenuModalContainer
  },
  data () {
    return {
      open: false,
      loaded: false,
      showMyEucapsMenu: false
    }
  },
  methods: {
    toggleNavbar () {
      this.open = !this.open
      this.loaded = true
    }
  }
}
</script>

import { get, post, put, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const addVideo = async (companySN, data) => post(`${url}/companies/${companySN}/add-videos`, data)
const editVideo = async (videoId, data) => put(`${url}/featured-videos/${videoId}`, data)
const deleteVideo = async (videoId) => destroy(`${url}/featured-videos/${videoId}`)
const fetchVideos = async () => get(`${url}/featured-videos`)
const scroll = async (link) => get(`${link}`)
const fetchVideoDetails = async (data) => get(`${url}/featured-videos/fetch-video-details`, data)

export default {
  addVideo,
  editVideo,
  deleteVideo,
  fetchVideos,
  fetchVideoDetails,
  scroll
}

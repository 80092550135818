<template>
  <div class="flex flex-col lg:flex-row w-full">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'ColumnWrapper',
  props: {
  }
}
</script>

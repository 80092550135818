<template>
  <div class="relative">
    <!-- <div class="flex  items-center text-white text-sm font-medium">
    <button
      id="menu-btn"
      class="block text-3xl focus:outline-none ml-3 mt-1"
      :class="open && 'open'"
      @click="$emit('toggle-navbar', $event)"
    >
      <Icon name="menu" />
    </button>
    <div
      id="menu"
      :class=" loaded ? open ? 'slide-in right-0' : 'slide-out' : '' "
      class="fixed  menu-slider h-full w-4/5 flex flex-col items-start p-8 mt-2 space-y-6 font-semibold text-xl text-white bg-custom-purple-500 top-0 z-20"
    >
      <button
        class="text-3xl"
        @click="$emit('toggle-navbar', $event)"
      >
        <Icon name="x" />
      </button>
      <RouterLink
        exact
        :to="{name: 'signup'}"
      >
        <button class="mr-6 lg:mr-14 border-b-2 border-transparent py-2 px-6">
          Sign up
        </button>
      </RouterLink>
      <RouterLink
        exact
        :to="{name: 'login'}"
        class="bg-custom-orange-400 py-2 px-6 rounded-full"
      >
        Sign in
      </RouterLink>
    </div>
  </div> -->
    <div class="flex items-center justify-between z-10 py-6 mx-auto px-2">
      <Icon
        name="menu"
        class="text-2xl flex cursor-pointer sm:pr-2 text-white z-60"
        @click="$emit('toggle-navbar')"
      />
      <LogoContainer />
      <Icon
        name="bell"
        class="text-2xl cursor-pointer text-white text-custom-purple-500"
      />
    </div>
  </div>
</template>
<script>
import LogoContainer from '@/modules/logo'

export default {
  name: 'Mobilenav',
  components: {
    LogoContainer,
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Boolean,
      default: false
    }
  },
emits: [
'toggle-navbar'
]
}
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply border-custom-green-500;
}
.menu-slider {
  transform: translateX(100%);
}
.slide-in {
  animation: slide-in 0.5s forwards;
}
.slide-out {
  animation: slide-out 0.9s backwards;
}
@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(-50%);
  }
  100% {
    width: translateX(100%);
  }
}
</style>

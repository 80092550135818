<template>
  <div class="w-full h-full flex-grow text-center flex-1 flex items-center justify-center">
    <div>
      <Title class="text-xl">
        {{ $t('Maintenance') }}
      </Title>
      <p>{{ $t('We currently run maintenance work in the app until clock', {clock: maintenanceTo}) }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MaintenanceModal',
  props: {
    maintenanceTo: {
      type: String,
      default: ''
    }
  }
}
</script>

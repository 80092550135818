import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/credit-safe`

const getCompanyCreditReport = async (data) => get(`${url}/credit-report-fetch/${data.companyId}`)
const getCompanyCreditReportPdf = async (data) => get(`${url}/credit-report-download`, data)
const validateCheckoutSession = async (data) => post(`${url}/credit-checkout-success`, { session_id: data.session_id })
const buyCreditReport = async (data) => get(`${url}/credit-report-buy/${data.companyId}`, { return_url: data.returnUrl, is_native: data.isNative })
const getMyCreditReportList = async (data) => get(`${url}/credit-report-list`)

export default {
  getCompanyCreditReport,
  getCompanyCreditReportPdf,
  validateCheckoutSession,
  buyCreditReport,
  getMyCreditReportList
}

<template>
  <div class="absolute inset-0">
    <div class="absolute text-shadow inset-0 z-40 text-gray-100 flex flex-col items-center justify-center text-3xl font-bold">
      <p><slot /></p>
      <p class="text-2xl font-semibold">
        Coming soon
      </p>
    </div>
    <div
      class="absolute inset-0 z-30 backdrop-filter backdrop-blur-xs bg-custom-purple-300 dark:bg-custom-purple-800 bg-opacity-75"
      :class="rounded"
    />
  </div>
</template>

<script>

export default {
  name: 'ComingSoon',
  props: {
    rounded: {
      type: String,
      default: 'rounded-xl'
    }
  }
}
</script>

<template>
  <BaseButton
    v-if="roundedButton"
    no-shadow
    :small-padding="smallPadding"
    class="flex items-center bg-none font-semibold focus:outline-none hover:border-gray-500 dark:text-custom-purple-100 text-custom-purple-500 border rounded-3xl space-x-1"
    @click="$emit('follow')"
  >
    <Icon :name="isFollowed? 'x' : 'plus'" />
    <p
      class=" flex flex-col flex-shrink-0 text-right"
      :class="isMenu ? 'hidden' : 'block' "
    >
      {{ isFollowed ? $t('Unfollow') : $t('Follow') }}
    </p>
  </BaseButton>

  <button
    v-else
    class="flex items-center bg-none focus:outline-none"
    @click="$emit('follow')"
  >
    <p
      v-if="text"
      :class="textColour"
    >
      <span>{{ isFollowed ? $t('Unfollow') : $t('Follow') }}</span>
    </p>
    <Icon
      v-else
      class="ml-1 text-xl dark:text-custom-purple-100"
      :class="margins ? 'mr-2' : ''"
      :solid="isFollowed"
      name="heart"
    />
  </button>
</template>

<script>

export default {
  name: 'CompanyFollow',
  props: {
    roundedButton: {
      type: Boolean,
      default: false
    },
    isFollowed: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    margins: {
      type: Boolean,
      default: false
    },
    textColour: {
      type: String,
      default: ''
    },
    smallPadding: {
      type: Boolean, 
      default: false
    },
    isMenu: {
      type: Boolean,
      default: false
    }
  },
emits: [
'follow'
]
}
</script>

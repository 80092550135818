<template>
  <div class="w-full mt-4 mb-2">
    <img
      :src="src"
      class="flex item-center w-full h-auto mr-2 inline-flex bg-gray-800 overflow-hidden"
    >
  </div>
</template>

<script>

export default {
  name: 'BaseImage',
  props: {
    src: { type: String, default: '' }
  }
}
</script>

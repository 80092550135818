import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/companies`

const fetchQuestionDocuments = async (companyId) => get(`${url}/${companyId}/question-documents`)
const createQuestionDocuments = async (companyId, data) => post(`${url}/${companyId}/question-documents`, data)

const fetchQuestions = async (companyId, questionDocumentId) => get(`${url}/${companyId}/question-documents/${questionDocumentId}/questions`)
const createQuestions = async (companyId, questionDocumentId, data) => post(`${url}/${companyId}/question-documents/${questionDocumentId}/questions`, data)
const takeResponsibilityForQuestions = async (companyId, questionDocumentId) => post(`${url}/${companyId}/question-documents/${questionDocumentId}/responsible`)

export default {
  fetchQuestionDocuments,
  createQuestionDocuments,
  fetchQuestions,
  createQuestions,
  takeResponsibilityForQuestions
}

import { get, post, put, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const fetchQuestionAnswers = async (companyId) => get(`${url}/companies/${companyId}/questions`)

const createQuestion = async (companyId, data) => post(`${url}/companies/${companyId}/questions`, { question_text: data })

const publishQuestion = async (companyId, questionId, data) => put(`${url}/companies/${companyId}/questions/${questionId}`,
  data
)

const destroyQuestion = async (companyId, questionId) => destroy(`${url}/companies/${companyId}/questions/${questionId}`)

const fetchFilteredQuestionAnswers = async (companyId, query) =>
  get(`${url}/companies/${companyId}/filter-question?filter_type=${query}`)

const toggleQuestionStatus = async (companyId, questionId) => post(`${url}/companies/${companyId}/questions/${questionId}/toggle-status`)

export default {
  fetchQuestionAnswers,
  createQuestion,
  publishQuestion,
  destroyQuestion,
  fetchFilteredQuestionAnswers,
  toggleQuestionStatus
}

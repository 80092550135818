<template>
  <RouterLink
    v-if="type === 'internal'"
    :to="to"
  >
    <slot />
  </RouterLink>
  <a
    v-else
    :href="to"
    target="_blank"
  >
    <slot />
  </a>
</template>

<script>

export default {
  name: 'Link',
  props: {
    type: {
      type: String,
      default: 'internal'
    },
    to: {
      type: Object || String,
      default: () => null
    }
  }
}
</script>

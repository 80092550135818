/**
 * Try to open the external app using URL sheme
 * @param URL appUri URL Scheme of the app
 * @param URL urlNotFoundApp where should go if app not found (if exists)
 * @param URL urlFoundApp where should go if app found (if exists)
 */
export const loadAppByUri = (appUri, urlNotFoundApp, urlFoundApp) => {
  var goToAppPage = null
  var clearEvents
  var setEvents
  var appWasFound
  var appWasNotFound
  var waiting = true

  /**
   * Cross browsers events top capture
   * the app openning
   */
  var eventsName = [ 'pagehide', 'blur' ]

  /**
   * If the app was founded
   * stop the timeout
   * go to urlFoundApp (if exists)
   */
  appWasFound = function (event) {
    if (!waiting) {
      return false
    }
    waiting = false
    clearEvents()
    if (urlFoundApp != null) {
      document.location = urlFoundApp
    }
  }

  /**
   * If app was not founded
   * call the location urlNotFoundApp,urlFoundApp
   */
  appWasNotFound = function (event) {
    if (!waiting) {
      return false
    }
    waiting = false
    clearEvents()
    console.log('chaning location to ' + urlNotFoundApp, urlFoundApp)
    if (urlNotFoundApp != null) {
      // alert('appWasNotFound')
      document.location = urlNotFoundApp
    }
  }

  /**
   * Set all the events to detected the
   * app beeing called
   */
  setEvents = function () {
    window.clearTimeout(goToAppPage)
    eventsName.map((eventName) => {
      // console.log('eventName = ', eventName)
      window.addEventListener(eventName, appWasFound)
    })
  }

  /**
   * Clear all the events what was waiting
   * the app calling
   */
  clearEvents = function () {
    console.log('clear events')
    eventsName.map((eventName) => {
      // console.log('removeEventListener ', eventName)
      window.document.removeEventListener(eventName, appWasFound)
    })
  }

  /**
   * After change the src of the iframe
   * the app caller must call some of
   * the events.
   */
  document.getElementById('go').setAttribute('src', appUri)
  setEvents()

  /**
   * If none of the events was called
   * after some wait, it is because
   * the app was not founded.
   */
  goToAppPage = setTimeout(
    appWasNotFound,
    1500
  )
}

export default { loadAppByUri }

// /**
//  * When document is ready
//  * Prepare all the links with the
//  * data-url-app attribute to call
//  * the loadAppByUri function
//  * @see loadAppByUri
//  */
// $(document).ready(function () {
//   $('a[data-uri-app]').on('click', function () {
//     loadAppByUri(
//       $(this).data('uri-app'),
//       $(this).data('url-app-not-found'),
//       $(this).data('url-app-found')
//     )
//     return false
//   })
// })

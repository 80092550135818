<template>
  <Network
    :is-on-line="isOnLine"
  />
</template>

<script>

import Network from './_components/Network'

export default {
  name: 'NetworkModule',
  components: {
    Network
  },
  data () {
    return {
      isOnLine: navigator.onLine
    }
  },
  mounted () {
    window.addEventListener('online', () => { this.isOnLine = true })
    window.addEventListener('offline', () => { this.isOnLine = false })
  }
}
</script>

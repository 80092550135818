import auth from './helpers/auth'
import eventBus from './eventbus'

import findApp from '@/js/helpers/findApp'
import { isMobile } from './helpers/mobile'
import { usePostsStore } from '../stores/posts'
import { useTodosStore } from '../stores/todos'
import { useAppsStore } from '../stores/app'
import { useUsersStore } from '../stores/users'
import { useSocketsStore } from '../stores/sockets'

import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (to.name === 'myFeed') {
      const postsStore = usePostsStore()
      if (
        postsStore.postsWithoutTypes &&
        postsStore.postsWithoutTypes['general-feed'] &&
        postsStore.postsWithoutTypes['general-feed'].length === 0
      ) {
        postsStore.setType('general-feed')
        return { x: 0, y: 0 }
      } else {
        return savedPosition
      }
    } else {
      // always scroll to top
      return { top: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Discover.vue'),
      meta: {
        layout: 'FrontLayout'
      }
    },
    {
      path: '/release-note',
      name: 'releaseNote',
      component: () => import('@/views/ReleaseNote.vue'),
     
    },
    {
      path: '/index.html',
      name: 'home-index',
      component: () => import('@/views/Discover.vue'),
      meta: {
        layout: 'FrontLayout'
      }
    },
    {
      path: '/hey-company',
      name: 'heyCompany',
      component: () => import('@/views/HeyCompany'),
      meta: {
        layout: 'FrontLayout'
      }
    },
    {
      path: '/hey-eucaps',
      name: 'heyEucaps',
      component: () => import('@/views/HeyEucaps'),
      meta: {
        layout: 'FrontLayout'
      }
    },
    {
      path: '/hey-investor',
      name: 'heyInvestor',
      component: () => import('@/views/HeyInvestor'),
      meta: {
        layout: 'FrontLayout'
      }
    },
    // {
    //   path: '/discover',
    //   name: 'discover',
    //   component: () => import('@/views/Discover'),
    // },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: () => import('@/views/Subscribe'),
      meta: {
        slide: 0,
        needLogin: true
      }
    },
    {
      path: '/followers/:id',
      name: 'followers',
      component: () => import('@/views/Followers')
    },
    {
      path: '/followings/:id',
      name: 'followings',
      component: () => import('@/views/Followings')
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: () => import('@/views/ForgotPassword'),
      meta: {
        slide: 0,
        layout: 'SignInLayout',
        needLogin: false
      }
    },
    {
      path: '/freja',
      name: 'freja',
      component: () => import('@/views/Freja'),
      meta: {
        slide: 0,
        needLogin: false
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login'),
      meta: {
        slide: 0,
        layout: 'SignInLayout',
        needLogin: false
      }
    },
    {
      path: '/trulioo/test',
      name: 'truliooTest',
      component: () => import('@/views/Trulioo'),
      meta: {
        slide: 0,
        layout: 'SignInLayout',
        needLogin: true
      }
    },
    {
      path: '/r/:lead',
      name: 'lead',
      component: () => import('@/views/Lead'),
      meta: {
        slide: 0,
        needLogin: false
      }
    },
    {
      path: '/l/:leadredirect',
      name: 'lead',
      component: () => import('@/views/Lead'),
      meta: {
        slide: 0,
        needLogin: false
      }
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/Signup'),
      meta: {
        slide: 0,
        layout: 'SignInLayout',
        needLogin: false
      }
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: () => import('@/views/Onboarding'),
      meta: {
        needLogin: false,
        layout: 'SignInLayout'
      }
    },
    {
      path: '/onboarding-company',
      name: 'onboarding-company',
      component: () => import('@/views/Onboarding'),
      meta: {
        needLogin: false,
        layout: 'SignInLayout'
      }
    },
    {
      path: '/onboarding-exchange',
      name: 'onboarding-exchange',
      component: () => import('@/views/ExchangeOnboarding'),
      meta: {
        needLogin: false,
        layout: 'SignInLayout'
      }
    },
    {
      path: '/verification',
      name: 'Verification',
      component: () => import('@/views/Verification'),
      meta: {
        needLogin: false,
        layout: 'SignInLayout'
      }
    },
    {
      path: '/email-verification',
      name: 'emailverification',
      component: () => import('@/views/EmailVerification'),
      meta: {
        slide: 0,
        needLogin: false
      }
    },

    {
      path: '/searchcompany',
      name: 'searchaddcompany',
      component: () => import('@/views/SearchAddCompany'),
      meta: {
        slide: 2,
        mainSwipe: true
      }
    },
    {
      path: '/members',
      name: 'members',
      component: () => import('@/views/Members'),
      meta: {
        slide: 2,
        mainSwipe: true
      }
    },
    {
      path: '/members/followings',
      name: 'memberFollowings',
      component: () => import('@/views/Members'),
      meta: {
        slide: 2,
        mainSwipe: true
      }
    },
    {
      path: '/profilemenu',
      name: 'profilemenu',
      component: () => import('@/views/ProfileMenu'),
      meta: {
        slide: 5,
        mainSwipe: true
      }
    },
    {
      path: '/featuredvideos',
      name: 'featuredVideos',
      component: () => import('@/views/FeaturedVideos')
    },
    {
      path: '/editprofile',
      redirect: { name: 'myEucapsSettings' },
      name: 'editprofile',
      component: () => import('@/views/EditProfile')
    },
    {
      path: '/verify',
      name: 'verify',
      component: () => import('@/views/Verify')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/views/Privacy'),
      meta: {
        needLogin: false
      }
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('@/views/Terms'),
      meta: {
        needLogin: false
      }
    },
    {
      path: '/company-users',
      name: 'companyUsers',
      component: () => import('@/views/CompanyUsers')
    },
    {
      path: '/rules',
      name: 'rules',
      component: () => import('@/views/Rules')
    },
    {
      path: '/premium',
      name: 'premium',
      component: () => import('@/views/Premium')
    },
    {
      path: '/holder-shares/:id',
      name: 'holdershares',
      component: () => import('@/views/HolderShares')
    },
    {
      path: '/profile',
      redirect: { name: 'myEucapsOverview' },
      name: 'profile',
      component: () => import('@/views/Profile'),
      meta: {
        slide: 5
      }
    },
    // {
    //   path: '/profile/lists',
    //   name: 'usersLists',
    //   component: () => import('@/views/UsersLists')
    // },
    {
      path: '/rooms',
      name: 'rooms',
      component: () => import('@/views/Rooms')
    },
    {
      path: '/room/:id',
      name: 'room',
      component: () => import('@/views/Room')
    },
    {
      path: '/thread/:id',
      name: 'posts',
      component: () => import('@/views/Post')
    },
    // {
    //   path: '/company-screener',
    //   name: 'companyScreener',
    //   component: () => import('@/views/CompanyScreener')
    // },
    // {
    //   path: '/list-screener',
    //   name: 'listScreener',
    //   component: () => import('@/views/ListScreener')
    // },
    {
      path: '/compare',
      name: 'compare',
      component: () => import('@/views/Compare')
    },
    {
      path: '/comment/:id',
      name: 'comment',
      component: () => import('@/views/Comment')
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/views/Notifications'),
      meta: {
        slide: 4,
        mainSwipe: true
      }
    },
    {
      path: '/otheruser/:id',
      name: 'otherUserProfile',
      component: () => import('@/views/OtherUserProfile'),
      meta: {
        slide: 6
      },
      children: [
        {
          path: '/otheruser/:id/flow',
          name: 'userFlow',
          component: () => import('@/views/UserFlow')
        },
        {
          path: '/otheruser/:id/company',
          name: 'userCompany',
          component: () => import('@/views/UserCompany')
        },
        {
          path: '/otheruser/:id/media',
          name: 'userMedia',
          component: () => import('@/views/UserMedia')
        }
      ]
    },
    {
      path: '/chats',
      name: 'chats',
      component: () => import('@/views/Chats'),
      meta: {
        slide: 3,
        mainSwipe: true
      }
    },
    {
      path: '/chats/:id',
      name: 'chat',
      component: () => import('@/views/Chat'),
      meta: {
        slide: 3
      }
    },
    {
      path: '/refresh',
      name: 'refresh',
      component: () => import('@/views/Refresh')
    },
    {
      path: '/highscore',
      name: 'highscore',
      component: () => import('@/views/HighScore')
    },
    {
      path: '/saved',
      name: 'saved',
      component: () => import('@/views/Saved')
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('@/views/Support')
    },
    {
      path: '/helpcenter',
      name: 'help-center',
      component: () => import('@/views/HelpCenter')
    },
    {
      path: '/achievements',
      name: 'achievements',
      component: () => import('@/views/Achievements')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('@/views/News')
    },
    {
      path: '/incidents',
      name: 'incidents',
      component: () => import('@/views/Incidents')
    },
    {
      path: '/report-incident',
      name: 'reportIncident',
      component: () => import('@/views/ReportIncident')
    },
    {
      path: '/incidents/:id',
      name: 'incident',
      component: () => import('@/views/Incident')
    },
    {
      path: '/advertisers',
      name: 'advertisers',
      component: () => import('@/views/Advertisers')
    },
    {
      path: '/addadvertiser',
      name: 'addAdvertiser',
      component: () => import('@/views/AddAdvertiser')
    },
    {
      path: '/:advertiserId/addadvertise',
      name: 'addAdvertise',
      component: () => import('@/views/AddAdvertise')
    },
    {
      path: '/:id/advertiser',
      name: 'advertiser',
      component: () => import('@/views/Advertiser')
    },
    {
      path: '/:advertiserId/:id/advertise',
      name: 'advertise',
      component: () => import('@/views/Advertise')
    },
    {
      path: '/talk',
      name: 'generalfeed',
      component: () => import('@/views/GeneralFeed')
    },
    {
      path: '/lists/:id',
      name: 'singleList',
      component: () => import('@/views/Lists')
    },
    {
      path: '/forum-and-discussion',
      name: 'forumAndDiscussions',
      component: () => import('@/views/Explore'),
      children: [
        {
          path: '',
          name: 'roomResults',
          component: () => import('@/views/RoomResults')
        },
        {
          path: 'thread',
          name: 'threadResults',
          component: () => import('@/views/ThreadResults')
        }
      ]
    },
    {
      path: '/explore',
      name: 'explore',
      component: () => import('@/views/Explore'),
      children: [
        {
          path: '',
          name: 'exploreResults',
          component: () => import('@/views/ExploreResults')
        },
        {
          path: 'map',
          name: 'exploreMaps',
          component: () => import('@/views/ExploreMaps')
        },
        {
          path: 'exchanges',
          name: 'exploreExchanges',
          component: () => import('@/views/ExploreExchanges')
        },
        {
          path: 'news',
          name: 'exploreNews',
          component: () => import('@/views/ExploreNews')
        },
        {
          path: 'companies',
          name: 'exploreCompanies',
          component: () => import('@/views/ExploreCompanies')
        },
        {
          path: 'lists',
          name: 'exploreLists',
          component: () => import('@/views/ExploreLists')
        },
        {
          path: 'media',
          name: 'exploreMedia',
          component: () => import('@/views/ExploreMedia')
        }
      ]
    },
    {
      path: '/history',
      name: 'history',
      component: () => import('@/views/Explore')
    },
    {
      path: '/exchange/:id',
      name: 'ExchangePage',
      component: () => import('@/views/ExchangePage'),
      children: [
        {
          path: '/exchange/:id',
          name: 'exchangeOverview',
          component: () => import('@/views/ExchangeOverview')
        },
        {
          path: '/exchange/:id/companies',
          name: 'exchangeCompanies',
          component: () => import('@/views/ExchangeCompanies')
        },
        // {
        //   path: '/exchange/:id/business-details',
        //   name: 'exchangeBusinessDetails',
        //   component: () => import('@/views/ExchangeBusinessDetails')
        // },
        // {
        //   path: '/exchange/:id/discussion-and-news',
        //   name: 'exchangeDiscussion',
        //   component: () => import('@/views/ExchangeDiscussion')
        // },
        {
          path: '/exchange/:id/media',
          name: 'exchangeMedia',
          component: () => import('@/views/ExchangeMedia')
        },
        {
          path: '/exchange/:id/rules-and-regulations',
          name: 'exchangeRulesAndRegulations',
          component: () => import('@/views/ExchangeRulesAndRegulations')
        }
      ]
    },
    {
      path: '/my-admin',
      name: 'myAdmin',
      component: () => import('@/views/MyAdmin'),
      children: [
        {
          path: '/my-admin/overview',
          name: 'myAdminOverview',
          component: () => import('@/views/AdminOverview')
        },
        {
          path: '/my-admin/polls',
          name: 'myAdminPolls',
          component: () => import('@/views/AdminPoll')
        }
      ]
    },
    {
      path: '/kyc',
      name: 'kyc',
      component: () => import('@/views/Kyc'),
      meta: {
        slide: 0,
        layout: 'SignInLayout',
        needLogin: false
      }
    },
    {
      path: '/my-eucaps',
      name: 'myEucaps',
      component: () => import('@/views/MyEucaps'),
      children: [
        {
          path: '/my-eucaps/overview',
          name: 'myEucapsOverview',
          component: () => import('@/views/ProfileOverview')
        },
        {
          path: '/my-eucaps/feed',
          name: 'myFeed',
          component: () => import('@/views/Feed')
        },
        {
          path: '/my-eucaps/lists',
          name: 'myEucapsLists',
          component: () => import('@/views/UserLists')
        },
        {
          path: '/my-eucaps/portfolio',
          name: 'myEucapsPortfolio',
          component: () => import('@/views/Transactions')
        },
        {
          path: '/my-eucaps/favorites',
          name: 'myEucapsFavorites',
          component: () => import('@/views/ProfileFavorites')
        },
        {
          path: '/my-eucaps/settings',
          name: 'myEucapsSettings',
          component: () => import('@/views/ProfileSettings')
        },
        {
          path: '/my-eucaps/my-company/:id?',
          name: 'myEucapsMyCompany',
          component: () => import('@/views/MyCompany')
        },
        {
          path: '/my-eucaps/marketplace',
          name: 'myEucapsMarketplace',
          component: () => import('@/views/Marketplace')
        },
        {
          path: '/my-eucaps/followers',
          name: 'myFollowers',
          component: () => import('@/views/MyFollowers')
        },
        {
          path: '/my-eucaps/followings',
          name: 'myFollowings',
          component: () => import('@/views/MyFollowings')
        }
      ]
    },
    {
      path: '/articles',
      name: 'blogs',
      component: () => import('@/views/AllBlogs')
    },
    {
      path: '/articles/create',
      name: 'BlogCreate',
      component: () => import('@/views/BlogCreate')
    },
    {
      path: '/articles/:name/edit',
      name: 'BlogEdit',
      component: () => import('@/views/BlogEdit')
    },

    {
      path: '/articles/:name',
      name: 'SingleBlog',
      component: () => import('@/views/SingleBlog')
    },

    {
      path: '/allowed-routes',
      name: 'AllowedRoutes',
      component: () => import('@/views/AllowedRoutes')
    },
    {
      path: '/:id',
      name: 'CompanyPage',
      component: () => import('@/views/CompanyPage'),
      meta: {
        slide: 2
      },
      children: [
        {
          path: '/:id',
          name: 'companyOverview',
          component: () => import('@/views/CompanyOverview')
        },
        // {
        //   path: "/:id/media/:media_id?",
        //   name: "companyMedia",
        //   component: () => import("@/views/CompanyMedia"),
        // },
        // {
        //   path: "/:id/details",
        //   name: "companyDetails",
        //   component: () => import("@/views/CompanyDetails"),
        // },
        {
          path: '/:id/chat/:room',
          name: 'companyChat',
          component: () => import('@/views/CompanyChat')
        },
        // {
        //   path: "/:id/triggers",
        //   name: "companyTriggers",
        //   component: () => import("@/views/CompanyTriggers"),
        // },
        // {
        //   path: "/:id/estimates",
        //   name: "companyEstimates",
        //   component: () => import("@/views/CompanyEstimates"),
        // },
        // {
        //   path: "/:id/ipo",
        //   name: "companyIPO",
        //   component: () => import("@/views/CompanyIPO"),
        // },
        // {
        //   path: "/:id/private",
        //   name: "companyPrivate",
        //   component: () => import("@/views/CompanyPrivate"),
        // },
        {
          path: '/:id/calendar',
          name: 'companyCalendar',
          component: () => import('@/views/CompanyCalendar')
        },
        {
          path: '/:id/documents',
          name: 'companyDocuments',
          component: () => import('@/views/CompanyDocuments')
        },
        // {
        //   path: "/:id/contacts",
        //   name: "companyContacts",
        //   component: () => import("@/views/CompanyContacts"),
        // },
        // {
        //   path: "/:id/board",
        //   name: "companyBoard",
        //   component: () => import("@/views/CompanyBoard"),
        // },
        {
          path: '/:id/brokerstats',
          name: 'companyBrokerStats',
          component: () => import('@/views/CompanyBrokerStats')
        },
        // {
        //   path: "/:id/owner",
        //   name: "companyOwners",
        //   component: () => import("@/views/CompanyOwners"),
        // },
        // {
        //   path: "/:id/members",
        //   name: "companyMembers",
        //   component: () => import("@/views/CompanyMembers"),
        // },
        // {
        //   path: "/:id/documents",
        //   name: "companyQuestionDocuments",
        //   component: () => import("@/views/CompanyQuestionDocuments"),
        // },
        {
          path: '/:id/companysettings',
          name: 'companySettings',
          component: () => import('@/views/CompanySettings')
        },
        {
          path: '/:id/documents/:questionDocumentId/questions',
          name: 'companyQuestions',
          component: () => import('@/views/CompanyQuestions')
        },
        {
          path: '/:id/pm',
          name: 'companyPressReleases',
          component: () => import('@/views/CompanyPressReleases')
        },
        {
          path: '/:id/pm/:pressReleaseId',
          name: 'companyPressRelease',
          component: () => import('@/views/CompanyPressRelease')
        },
        {
          path: '/:id/insiders',
          name: 'companyInsiderTrade',
          component: () => import('@/views/InsiderTrade')
        },
        {
          path: '/:id/financials',
          name: 'companyFinancials',
          component: () => import('@/views/CompanyFinancials')
        },
        {
          path: '/:id/holders',
          name: 'companyHolders',
          component: () => import('@/views/CompanyHolders')
        },
        {
          path: '/:id/analysis',
          name: 'companyAnalysis',
          component: () => import('@/views/CompanyAnalysis')
        },
        {
          path: '/:id/feed',
          name: 'companyDiscussion',
          component: () => import('@/views/CompanyDiscussion')
        },
        {
          path: '/:id/investmentPitch',
          name: 'investmentPitch',
          component: () => import('@/views/CompanyBusinessDetails')
        },
        {
          path: '/:id/events',
          name: 'companyEvents',
          component: () => import('@/views/CompanyEvents')
        },
        {
          path: '/:id/media',
          name: 'companyMedia',
          component: () => import('@/views/CompanyMedia')
        }
      ]
    }
  ]
})

window.addEventListener('popstate', () => {
  // console.log('POPSTATE YEAH')
  eventBus.$emit('on-go-back')
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach(async (to, from, next) => {
  const todosStore = useTodosStore()
  const appsStore = useAppsStore()
  const usersStore = useUsersStore()
  const socketsStore = useSocketsStore()
  todosStore.reset()

  const isMobile =  /iPhone|iPad|iPod|Android|IEMobile|WPDesktop|BlackBerry|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 485
  const scriptId = 'hs-script-loader'
  const scriptSrc = '//js-eu1.hs-scripts.com/143386380.js'

  // Array of paths where the script should be disabled
  const disableScriptPaths = ['signup', 'login', 'chat', 'kyc'];

  // Check if the script is already loaded
  let script = document.getElementById(scriptId)

  if (disableScriptPaths.includes(to.name) && isMobile) {
    // Remove the script if it exists
    if (script) {
      script.remove()
    }
  } else {
    // Add the script if it doesn't exist
    if (!script) {
      script = document.createElement('script')
      script.id = scriptId
      script.src = scriptSrc
      script.async = true
      script.defer = true
      document.head.appendChild(script)
    }
  }

  let lead = to.params.lead
  let leadRedirect = to.params.leadredirect
  if (lead || leadRedirect) {
    const ifrm = document.createElement('iframe')
    ifrm.style.display = 'none'
    ifrm.setAttribute('id', 'go')
    document.body.appendChild(ifrm)
    await appsStore.lead({
      lead: lead || leadRedirect,
      platform_key: appsStore.platformKey
    })

    if (leadRedirect) {
      window.location.href = 'https://get.eucaps.com'
    } else {
      if (isMobile()) {
        findApp.loadAppByUri('axyer://', 'https://get.eucaps.com', 'axyer://generalfeed')
      } else {
        next({ name: 'myFeed' })
      }
    }

    return
  }

  if (to.fullPath.indexOf('/' + appsStore.fixedHash) > -1) {
    if (to.matched.length === 0) {
      const path = to.fullPath.split('/' + appsStore.fixedHash)[0]
      next(path)
    } else {
      next({ name: to.matched[0].name })
    }
  }

  

  const user = usersStore.user
  const isGuestUser =
    (user && !user.profile_type) || (user && user.profile_type && user.profile_type.indexOf('GuestUser') > -1)

  const tryToAccessUserProtectedUrlWithoutAccess =
    to.matched.some((m) => m.meta || m.meta.needLogin) && isGuestUser && appsStore.isNative

  if (tryToAccessUserProtectedUrlWithoutAccess) {
    auth.clearToken()
    next({ name: 'login' })
  }

  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') ? localStorage.isLoggedIn : false)

  if (isLoggedIn) {
    usersStore.fetch()
    usersStore.visited(to.fullPath)
    socketsStore.connect()
  } else if (auth.isAuthenticated()) {
    localStorage.isLoggedIn = true
    usersStore.fetch()
    usersStore.visited(to.fullPath)
    socketsStore.connect()
  } else if (auth.hasDSecret()) {
    usersStore.fetch()
    usersStore.visited(to.fullPath)
    socketsStore.connect()
  } else {
    usersStore.visited(to.fullPath)
  }

  if(from.name?.indexOf('explore') > -1 && to.name?.indexOf('explore') > -1) {
    if(!hasQueryParams(to) && hasQueryParams(from)){
      next({name: to.name, query: from.query});
    }
  }

  next()
})

router.afterEach((to) => {
  const appsStore = useAppsStore()
  try {
    // console.log(store.getters['app/isNative'])
    if (appsStore.isNative) {
      window.gtag('event', 'screen_view', {
        app_name: 'Eucaps',
        screen_name: to.name
      })
    } else {
      window.gtag('event', 'page_view', {
        page_title: to.name,
        page_location: to.path,
        send_page_view: true
      })
    }
  } catch (error) {
    console.error(error)
  }
})

export default router

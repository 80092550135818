<template>
  <div
    v-if="!isOnLine"
    class="flex flex-col w-full top-0 left-0 z-50"
  >
    <div
      class="bg-gray-400 text-white p-2 text-xs flex justify-center"
    >
      <div>{{ $t('Offline') }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Network',
  props: {
    isOnLine: { type: Boolean, default: false }
  }
}
</script>

<template>
  <div>
    <!-- <div
      class="flex md:flex-row flex-col justify-center items-center "
    >
      <div class="gap-x-2 flex items-center">
        <div
          v-for="tag,index in selectedTags"
          :key="index"
          class="md:text-2xl text-lg text-custom-purple-500 font-bold text-center flex justify-items-center dark:text-custom-purple-100"
        >
          <span>{{ tag }}</span>
          <Icon
            name="x-circle"
            class="text-custom-orange-400 cursor-pointer"
            solid
            @click="$emit('remove', tag)"
          />
        </div> -->
    <div class="flex md:flex-row flex-col justify-center items-center gap-x-2 flex-wrap">
      <div
        v-for="(tag, index) in selectedTags"
        :key="index"
        class="text-2xl font-bold text-center flex justify-items-center"
        :class="[
          (tag.type === 'room' || tag.type === 'thread') && !isForumAndDiscussions
            ? 'text-gray-400'
            : 'text-custom-purple-500 dark:text-custom-purple-100'
        ]"
      >
        <span>{{ tag.label.length > 20 ? `${tag.label.substring(0, 20)}...` : tag.label }}</span>
        <Icon
          name="x-circle"
          class="cursor-pointer"
          :class="[
            (tag.type === 'room' || tag.type === 'thread') && !isForumAndDiscussions
              ? 'text-gray-400'
              : 'text-custom-orange-400'
          ]"
          solid
          @click="$emit('remove', tag.label)"
        />
      </div>
      <div class="md:text-xl text-lg text-custom-purple-500 font-bold dark:text-custom-purple-100 flex items-center gap-x-2">
        <span
          v-if="selectedTags.length > 0"
          class="hidden md:inline"
        >-</span> Generated <CircleLoader
          v-if="countLoading"
          small
        />
        <span v-else>{{ count }}</span> results
      </div>
    </div>
    <p class="md:hidden mt-2 text-center font-normal text-custom-purple-500 dark:text-custom-purple-100">
      Do you want to refine?
    </p>
    <!-- Display a add tag button when there is no tag -->
    <div
      class="flex justify-center items-center"
    >
      <Button
        class="mt-4 px-3 py-2 border-2 text-custom-purple-500 font-bold rounded-full text-xs flex items-center hover:bg-custom-purple-50 dark:hover:bg-custom-purple-450"
        @click="$emit('focus-tag-input')"
      >
        <Icon
          name="plus"
          class="mr-2 dark:text-custom-purple-100"
        />
        <span class="dark:text-custom-purple-100"> Add tag to refine </span>
      </Button>
    </div>
    <TopTagSearchContainer
      v-if="isMobile"
      source="mobile"
      :is-filtration="true"
    />
  </div>
</template>

<script>
import TopTagSearchContainer from '@/modules/topTagSearch'

export default {
  name: 'SelectedTagSection',
  components: {
    TopTagSearchContainer
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    selectedTags: {
      type: Array,
      default: () => []
    },
    countLoading: {
      type: Boolean,
      default: false
    },
    isForumAndDiscussions: {
      type: Boolean,
      default: false
    },
	isMobile: {
      type: Boolean,
      default: false
    },
  },
  emits: ['remove', 'focus-tag-input']
}
</script>

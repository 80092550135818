import { get, put } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const fetch = async () => get(`${url}/strategies`)
const toggleCompanyStrategy = async (companySN, strategyId) => put(`${url}/companies/${companySN}/strategies/${strategyId}`)

export default {
  fetch,
  toggleCompanyStrategy
}

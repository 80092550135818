<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'GridFourColumn'
}
</script>

<template>
  <div v-if="notification.notifiable">
    <div>
      <Notification
        :pivot="notification.pivot"
        @remove="$emit('remove', { id: notification.id })"
      >
        <div @click="$parent.redirectProfile({ notificationId: notification.id, query: { navigate: 'tradingRef' } })">
          <div>
            <div
              v-if="notification.notifiable.status === 'O'"
              class="w-full flex items-center dark:text-white"
            >
              <div>
                {{ $t('Your trading account is now active. Please go to My portfolio to add funds, Happy trading!') }}
              </div>
            </div>
            <div
              v-if="notification.notifiable.status === 'A'"
              class="w-full flex dark:text-white items-center"
            >
              <div>
                {{ $t('Your trading account request has been canceled due to inactivity. Please try again or contact support@eucaps.com.') }}
              </div>
            </div>
            <div
              v-else-if="notification.notifiable.status === 'N' || notification.notifiable.status === 'P'"
              class="w-full flex dark:text-white items-center"
            >
              <div>
                {{ $t('Your trading account is in progress - Please wait while your application is being reviewed. We will notify you by email once this is complete.') }}
              </div>
            </div>
            <div
              v-else-if="notification.notifiable.status === 'C'"
              class="w-full flex dark:text-white items-center"
            >
              <div>
                {{ $t('Your trading account has been closed. Please register again or contact support@eucaps.com.') }}
              </div>
            </div>
            <div
              v-else-if="notification.notifiable.status === 'R'"
              class="w-full flex dark:text-white items-center"
            >
              <div>
                {{ $t('Your trading account request was rejected due to compliance issues. Please try again with other details or contact support@eucaps.com.') }}
              </div>
            </div>
            <div
              v-else-if="notification.notifiable.status === 'M'"
              class="w-full flex dark:text-white items-center"
            >
              <div>
                {{ $t('Please wait while your trading account request is being manually reviewed. We will notify you by email once this is complete or contact support@eucaps.com.') }}
              </div>
            </div>
          </div>

          <div class="text-xs mr-6">
            <span class="font-bold">

              {{ notification.notifiable.message }}
              <div>{{ time }}</div>
            </span>
          </div>
        </div>
      </Notification>
    </div>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationTradingAccountStatus',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => { } },
    time: { type: String, default: '' }
  },
  emits: [
    'remove'
  ],
}
</script>

import { get, post, put, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/eventCalendar`

const getEventsQuarterly = async (payload) => get(`${url}/quarterly/company/${payload.companyId}`)
const addEvent = async (payload) => post(`${url}/create`, payload)
const updateEvent = async (payload) => put(`${url}/update/${payload.id}`, payload)
const deleteEvent = async (payload) => destroy(`${url}/delete/${payload.id}`, payload)

export default {
  getEventsQuarterly,
  addEvent,
  updateEvent,
  deleteEvent
}

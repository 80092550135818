const NEW_POST = 'App\\Models\\NotificationNewPost'
const PRESS_RELEASE = 'App\\Models\\NotificationCompanyPressRelease'
const NEW_FOLLOWER = 'App\\Models\\NotificationUserFollowers'
const LIKE_POST = 'App\\Models\\NotificationLikePost'
const NEW_COMMENT = 'App\\Models\\NotificationNewComment'
const NEW_SUB_COMMENT = 'App\\Models\\NotificationCommentComment'
const LIKE_COMMENT = 'App\\Models\\NotificationLikeComment'
const COMPANY_ASSET = 'App\\Models\\NotificationCompanyAsset'
const COMPANY_BOARD_MEMBER = 'App\\Models\\NotificationCompanyBoardMember'
const COMPANY_DOCUMENT = 'App\\Models\\NotificationCompanyDocument'
const COMPANY_CALENDAR_EVENT = 'App\\Models\\NotificationCompanyCalendraEvent'
const TRIGGER_LIKE_COMMENT = 'App\\Models\\NotificationLikeTriggerComment'
const TRIGGER_COMMENT = 'App\\Models\\NotificationTriggerComment'
const TRIGGER_DELETE = 'App\\Models\\NotificationDeleteTrigger'
const TRIGGER_VALIDATION = 'App\\Models\\NotificationValidationTrigger'
const COMPANY_TRIGGER = 'App\\Models\\NotificationCompanyTrigger'
const COMPANY_CONTACT = 'App\\Models\\NotificationCompanyContact'
const POST_SHARE = 'App\\Models\\NotificationPostShare'
const CONTACT_DELETE = 'App\\Models\\NotificationDeleteContact'
const NEW_SHARE = 'App\\Models\\NotificationNewShare'
const NEW_INSIDER_TRADE = 'App\\Models\\NotificationNewInsiderTrade'
const MENTIONED_IN_POST = 'App\\Models\\NotificationMentionedInPost'
const MENTIONED_IN_COMMENT = 'App\\Models\\NotificationMentionedInComment'
const MENTIONED_IN_COMPANY_CHAT = 'App\\Models\\NotificationMentionedInCompanyChat'
const KYC_COMPLETED = 'App\\Models\\NotificationKYCCompleted'
const FUNDS_DEPOSIT_INITIATED = 'App\\Models\\NotificationFundsDepositInitiated'
const FUNDS_DEPOSIT_SUCCESS = 'App\\Models\\NotificationFundsDepositSuccess'
const FUNDS_NOT_DEPOSITED = 'App\\Models\\NotificationFundsNotDeposited'
const NOTIFICATION_UPLOAD_DOCUMENT = 'App\\Models\\NotificationUploadDocument'
const NOTIFICATION_RE_UPLOAD_DOCUMENT = 'App\\Models\\NotificationReUploadDocument'
export const allTypes = [ NEW_POST, PRESS_RELEASE, NEW_FOLLOWER, LIKE_POST, NEW_COMMENT, NEW_SUB_COMMENT, LIKE_COMMENT, COMPANY_ASSET, COMPANY_BOARD_MEMBER, COMPANY_DOCUMENT, COMPANY_CALENDAR_EVENT, TRIGGER_LIKE_COMMENT, TRIGGER_COMMENT, TRIGGER_DELETE, TRIGGER_VALIDATION, COMPANY_TRIGGER, COMPANY_CONTACT, POST_SHARE, CONTACT_DELETE, NEW_SHARE, NEW_INSIDER_TRADE, MENTIONED_IN_POST, MENTIONED_IN_COMMENT, MENTIONED_IN_COMPANY_CHAT, KYC_COMPLETED, FUNDS_DEPOSIT_INITIATED, FUNDS_DEPOSIT_SUCCESS, FUNDS_NOT_DEPOSITED, NOTIFICATION_UPLOAD_DOCUMENT,NOTIFICATION_RE_UPLOAD_DOCUMENT ]

export { NEW_POST, PRESS_RELEASE, NEW_FOLLOWER, LIKE_POST, NEW_COMMENT, NEW_SUB_COMMENT, LIKE_COMMENT, COMPANY_ASSET, COMPANY_BOARD_MEMBER, COMPANY_DOCUMENT, COMPANY_CALENDAR_EVENT, TRIGGER_LIKE_COMMENT, TRIGGER_COMMENT, TRIGGER_DELETE, TRIGGER_VALIDATION, COMPANY_TRIGGER, COMPANY_CONTACT, POST_SHARE, CONTACT_DELETE, NEW_SHARE, NEW_INSIDER_TRADE, MENTIONED_IN_POST, MENTIONED_IN_COMMENT, MENTIONED_IN_COMPANY_CHAT, KYC_COMPLETED, FUNDS_DEPOSIT_INITIATED, FUNDS_DEPOSIT_SUCCESS, FUNDS_NOT_DEPOSITED, NOTIFICATION_UPLOAD_DOCUMENT,NOTIFICATION_RE_UPLOAD_DOCUMENT }

export default { NEW_POST, PRESS_RELEASE, NEW_FOLLOWER, LIKE_POST, NEW_COMMENT, NEW_SUB_COMMENT, LIKE_COMMENT, COMPANY_ASSET, COMPANY_BOARD_MEMBER, COMPANY_DOCUMENT, COMPANY_CALENDAR_EVENT, TRIGGER_LIKE_COMMENT, TRIGGER_COMMENT, TRIGGER_DELETE, TRIGGER_VALIDATION, COMPANY_TRIGGER, COMPANY_CONTACT, POST_SHARE, CONTACT_DELETE, NEW_SHARE, NEW_INSIDER_TRADE, MENTIONED_IN_POST, MENTIONED_IN_COMMENT, MENTIONED_IN_COMPANY_CHAT, KYC_COMPLETED, FUNDS_DEPOSIT_INITIATED, FUNDS_DEPOSIT_SUCCESS, FUNDS_NOT_DEPOSITED, NOTIFICATION_UPLOAD_DOCUMENT,NOTIFICATION_RE_UPLOAD_DOCUMENT }

<template>
  <div v-if="notification.notifiable">
    <div>
      <Notification
        :pivot="notification.pivot"
        @remove="$emit('remove', { id: notification.id })"
      >
        <div @click="$parent.redirectProfile({ notificationId: notification.id, query: { navigate: 'tradingRef' } })">
          <div v-if="notification.notifiable.form_number === '8001'">
            <div v-if="notification.notifiable.status === 'Received- being processed'">
              {{ $t('Your Proof of Identity document is been submitted and being processed') }}
            </div>
            <div v-else-if="notification.notifiable.status === 'Document is already processed or is not required'">
              {{ $t('Your Proof of Identity document is been accepted and processed successfully') }}
            </div>
            <div v-else-if="notification.notifiable.status === 'Accepted'">
              {{ $t('Your Proof of Identity document is been accepted') }}
            </div>
            <div v-else>
              {{ $t('Your Proof of Identity document has been ') }} {{ notification.notifiable.status }}
            </div>
          </div>

          <div v-else>
            <div v-if="notification.notifiable.status === 'Received- being processed'">
              {{ $t('Your Proof of Address document is been submitted and being processed') }}
            </div>
            <div v-else-if="notification.notifiable.status === 'Document is already processed or is not required'">
              {{ $t('Your Proof of Address document is been accepted and processed successfully') }}
            </div>
            <div v-else-if="notification.notifiable.status === 'Accepted'">
              {{ $t('Your Proof of Address document is been accepted') }}
            </div>
            <div v-else>
              {{ $t('Your Proof of Address document has been ') }} {{ notification.notifiable.status }}
            </div>
          </div>

          <div class="text-xs mr-6">
            <span class="font-bold">
              <div>{{ time }}</div>
            </span>
          </div>
        </div>
      </Notification>
    </div>
  </div>
</template>
<script>
import Notification from './Notification'
export default {
  name: 'NotificationDamPendingTaskUpdate',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => { } },
    time: { type: String, default: '' }
  },
  emits: [
    'remove'
  ],
}
</script>
<template>
  <img
    :srcset="srcset"
    :src="src[0].url"
  >
</template>

<script>

export default {
  name: 'SourceImage',
  props: {
    srcset: { type: String, default: '' },
    src: { type: Array, default: () => [] }
  }
}
</script>

import { get, post, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`
const fetchFilterData = async () => get(`${url}/screener-filter-data`)
const fetchCompanyFilters = async () => get(`${url}/users/fetch-company-filters`)
const saveCompanyFilters = async (data) => post(`${url}/users/add-company-filters`, data)
const deleteFilter = async (id) => destroy(`${url}/users/${id}/delete-company-filters`)
const updateCompanyFilters = async (id, data) => post(`${url}/users/${id}/update-company-filters`, data)
export default {
  fetchFilterData,
  fetchCompanyFilters,
  deleteFilter,
  saveCompanyFilters,
  updateCompanyFilters
}

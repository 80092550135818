<template>
  <div
    v-if="title"
    class="flex justify-between items-center"
  >
    <slot name="title">
      <p class="text-xl font-bold text-custom-purple-500 dark:text-custom-purple-100">
        {{ title }}
      </p>
    </slot>
  </div>
</template>

<script>

export default {
  name: 'ModalHeader',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div class="space-y-5">
    <form
      class="grid my-4 text-black gap-5"
      @submit.prevent="$emit('on-submit')"
    >
      <div class="font-bold text-4xl">
        {{ $t('Create account') }}
      </div>

      <div class="font-medium">
        {{ $t('The-one-stock-shop for European growth Companies.') }}
      </div>
      <div>
        <label class="font-bold">
          {{ $t('Email address') }}
        </label>
        <BaseInput
          autocomplete="email"
          type="email"
          :has-dark-mode="false"
          :required="true"
          :minlength="1"
          :border="checkAvailabilityError && checkAvailabilityError==='Email is already in use' ? 'border border-custom-red-500 dark:border-custom-red-500' : '' "
          class="placeholder-custom-purple-300 w-full font-semibold !rounded-md"
          :placeholder="$t('Ex. shaera@eucaps.com')"
          shadow="shadow-md"
          :model-value="email"
          @focus="$emit('reset-error')"
          @update:model-value="$emit('email', $event)"
        />

        <div
          v-if="checkAvailabilityError && checkAvailabilityError==='Email is already in use'"
          class="pt-2 text-custom-red-500 font-medium"
        >
          {{ checkAvailabilityError }}
        </div>
      </div>
      <div>
        <label class="font-bold">
          {{ $t('Username') }}
        </label>
        <BaseInput
          autocomplete="username"
          type="text"
          :has-dark-mode="false"
          :required="true"
          :minlength="1"
          :border="checkAvailabilityError && checkAvailabilityError==='Username is already in use' ? 'border border-custom-red-500 dark:border-custom-red-500' : '' "
          class="placeholder-custom-purple-300 w-full font-semibold !rounded-md"
          :placeholder="$t('Ex. shaera1')" 
          shadow="shadow-md"
          :model-value="username"
          @focus="$emit('reset-error')"
          @update:model-value="$emit('username', $event)"
        />
        <div
          v-if="checkAvailabilityError && checkAvailabilityError==='Username is already in use'"
          class="pt-2 text-custom-red-500 font-medium"
        >
          {{ checkAvailabilityError }}
        </div>
      </div>
      <div class="text-custom-purple-500 font-medium text-sm">
        <div class="flex mt-4">
          <input
            :id="'checkbox'"
            name="oiasjdoihfeoiuhwoiuahfeiuwehfi2"
            type="checkbox"
            :checked="terms"
            required
            class="mr-2 text-sm"
            autocomplete="off"
            @change="$emit('terms')"
          >
          <label
            :for="'checkbox'"
            class="w-full inline-block"
          >
            {{ $t('I hereby accept') }} 
            <a
              class="text-custom-orange-400 font-medium cursor-pointer"
              target="_blank"
              href="https://143386380.hs-sites-eu1.com/terms-of-service-website"
            >{{ $t('terms and conditions') }}
            </a></label>
        </div>
      </div>
      <NeutralButton
        type="submit"
        class="w-full text-md focus:outline-none disabled:opacity-50"
      >
        {{ $t("Continue") }}
      </NeutralButton>
    </form>

    <div class="w-full flex justify-center items-center ">
      <button
        class="mt-5 flex justify-center items-center focus:outline-none text-gray-900 gap-2"
        @click="$router.push({ name: 'login' })"
      >
        <div class="font-medium">
          {{ $t('Already have an account?') }}
        </div>
        <div class="font-semibold flex item-center">
          {{ $t('Login') }}
        </div>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SignupFormSection',

  props: {
    username: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    terms: {
      type: Boolean,
      default: false
    },
    checkAvailabilityError:{
      type: String,
      default: ''
    },
   
  },
  emits: [
    'username',
    'email',
    'on-submit',
    'terms',
    'reset-error',
   
    // 'show-terms'
  ]

}
</script>

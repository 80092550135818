<template>
  <div class="flex-1 justify-start divide-y divide-gray-300 dark:divide-custom-purple-300">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'ListItem'
}
</script>

<template>
  <div class="relative ml-4 flex justify-center items-center flex-grow-0 w-10 h-6 bg-gray-200 dark:bg-custom-purple-450 rounded-md p-4">
    <div
      class="cursor-pointer absolute inset-0 flex items-center justify-center"
      @click="showMenu = !showMenu"
    >
      <DotMenu
        horizontal
        class=""
      />
    </div>
    <div
      v-if="showMenu"
      class="w-64 z-70 absolute top-4 right-2 rounded-lg shadow-sm dark:shadow-sm-dark border  dark:border-custom-purple-300 bg-white dark:bg-custom-purple-450 dark:text-white"
    >
      <slot />
    </div>
    <TransparentBackgroundForMenus
      v-if="showMenu"
      @click="showMenu = !showMenu"
    />
  </div>
</template>

<script>

export default {
  name: 'DotMenuPopup',
  data () {
    return {
      showMenu: false
    }
  }
}
</script>

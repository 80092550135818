<template>
  <div class="hidden lg:block w-1/2 flex-shrink-0 h-full" />
</template>

<script>

export default {
  name: 'TransparentGridBlock'
}
</script>

<template>
  <div
    class=" bg-custom-light-header dark:bg-custom-dark-header text-white  rounded-sm"
    :class="big ? 'text-sm py-0 px-2' : 'text-2xs px-1'"
  >
    {{ $t('PRIVATE') }}
  </div>
</template>

<script>

export default {
  name: 'PrivateBadge',
  props: {
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>

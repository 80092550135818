<template>
  <SquareImage
    src="/img/document.jpg"
    :big="big"
  />
</template>

<script>
export default {
  name: 'ThumbnailDocument',
  props: {
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>

import { get, post } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const latestVersion = async () => get(`${url}/latest-version`)
const maintenance = async () => get(`${url}/maintenance`)
const lead = async (data) => post(`${url}/lead`, data)

export default {
  latestVersion,
  maintenance,
  lead
}

<template>
  <div>
    <div
      v-if="showMyEucapsMenu"
      class="sm:mt-0 w-screen h-[90vh] overflow-y-auto sm:w-96"
      @meun-clicked="$emit('toggle-my-eucaps-menu')"
    >
      <div
        class="relative min-h-min bg-white text-custom-purple-500 dark:text-custom-purple-100 dark:bg-custom-purple-500 pb-4 pt-0 sm:rounded-xl shadow-sm dark:shadow-sm-dark"
      >
        <div class="flex w-full borer-b">
          <TabButton
            :selected-tab="selectedTab"
            :parent-route="parentRoute"
            source="mobile"
            @change="changeTab"
          />
        </div>
        <div
          :class="isLoggedIn ? 'pb-0' : 'pb-2'"
          class="text-sm pt-4 flex flex-col space-y-4 justify-center items-center"
        >
          <TopTagSearchContainer
            source="mobile"
            @toggle="$emit('toggle-my-eucaps-menu')"
          />
          <span
            class="text-custom-lightPurple-400 dark:text-custom-purple-100 font-bold"
            @click="showHistory = !showHistory"
          >{{ showHistory ? 'Hide search history' : 'Show search history' }}</span>
          <div
            v-if="showHistory"
            class="h-28 overflow-y-scroll w-full dark:text-custom-purple-100"
          >
            <div
              v-if="history.length === 0"
              class="px-10 py-2"
            >
              No history found
            </div>
            <div
              v-for="item in history"
              :key="item.id"
              @click="explore(item)"
            >
              <p
                v-for="(t, index) in item.tags"
                :key="index"
                class="px-10 py-2"
              >
                {{ t }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="isLoggedIn"
          class="p-5 pb-2 pt-0 flex flex-col justify-start"
        >
          <div class="flex items-center space-x-2">
            <Avatar
              default-class="cursor-pointer w-12 h-12"
              :size="45"
              :stock-user="user.profile"
              :use-premium="false"
            />
            <h3 class="text-center cursor-pointer">
              {{ user.profile.full_name }}
            </h3>
          </div>
          <div class="flex justify-between mt-2 sm:mt-4 space-x-4">
            <RouterLink
              class="text-center cursor-pointer font-medium dark:text-custom-purple-100 text-gray-600 border border-custom-purple-400 hover:bg-gray-100 hover:dark:bg-custom-purple-450 dark:bg-custom-purple-400 w-1/2 py-2 rounded-full"
              :to="{ name: 'myEucapsOverview' }"
              @click="$emit('toggle-my-eucaps-menu')"
            >
              <span>{{ $t('My profile') }}</span>
            </RouterLink>
            <RouterLink
              class="flex items-center justify-center cursor-pointer font-medium dark:text-custom-purple-100 text-gray-600 border border-custom-purple-400 hover:bg-gray-100 w-1/2 py-2 hover:dark:bg-custom-purple-450 dark:bg-custom-purple-400 rounded-full"
              :to="{ name: 'myFeed' }"
              @click="$emit('toggle-my-eucaps-menu')"
            >
              <span>{{ $t('My Feed') }}</span>
            </RouterLink>
          </div>
        </div>
        <div class="flex flex-col">
          <RouterLink
            class="flex items-center font-medium border-b border-t p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500 dark:border-custom-purple-400"
            :to="{ name: isLoggedIn ? 'premium' : 'login' }"
            @click="$emit('toggle-my-eucaps-menu')"
          >
            <img
              src="/img/crown.png"
              class="premium-icon mr-2.5 h-4 w-4"
            >
            <span>Premium</span>
          </RouterLink>
          <RouterLink
            class="flex items-center font-medium border-b p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500 dark:border-custom-purple-400 relative"
            :to="{ name: isLoggedIn ? 'chats' : 'login' }"
            @click="$emit('toggle-my-eucaps-menu')"
          >
            <Bubble
              v-if="unreadMessages > 0"
              small
              background="bg-custom-orange-400"
              color="text-white"
              font-size="text-3xs"
              class="absolute rounded-full top-4 left-1 font-semibold"
            >
              {{ unreadMessages }}
            </Bubble>
            <Icon
              name="chat"
              class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
              solid
            />
            <span>Chat</span>
          </RouterLink>
          <RouterLink
            class="flex items-center font-medium border-b p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500 dark:border-custom-purple-400"
            :to="{ name: isLoggedIn ? 'members' : 'login' }"
            @click="$emit('toggle-my-eucaps-menu')"
          >
            <Icon
              name="user-circle"
              class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
              solid
            />
            <span>Members</span>
          </RouterLink>
          <RouterLink
            class="flex items-center font-medium border-b dark:border-custom-purple-400 p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500"
            :to="{ name: 'myEucapsSettings' }"
            @click="$emit('toggle-my-eucaps-menu')"
          >
            <Icon
              name="cog"
              class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
              solid
            />
            <span>Settings</span>
          </RouterLink>
          <RouterLink
            class="flex items-center font-medium border-b dark:border-custom-purple-400 p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500"
            :to="{ name: 'support' }"
            @click="$emit('toggle-my-eucaps-menu')"
          >
            <Icon
              name="help-circle"
              class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
              solid
            />
            <span>Support</span>
          </RouterLink>
          <a
            target="_blank"
            class="flex items-center font-medium border-b dark:border-custom-purple-400 p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-500"
            :href="'https://knowledge.eucaps.com/guides-demos'"
            @click="$emit('toggle-my-eucaps-menu')"
          >
            <Icon
              name="chalkboard"
              class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
              solid
            />
            <span>Guides & demos</span>
          </a>
          <div
            v-if="isLoggedIn"
            class="w-full"
          >
            <RouterLink
              v-if="!isVerified"
              :to="{ name: 'signup' }"
              class="px-4 text-white h-14 cursor-pointer flex items-center bg-custom-orange-400 hover:bg-custom-orange-300"
            >
              <Icon
                name="id-card"
                class="mr-2 text-white text-lg"
                solid
              />
              <span class="font-medium">{{ $t('Verify your account') }}</span>
              <Icon
                name="chevron-right"
                class="ml-2 text-white text-lg"
                solid
              />
            </RouterLink>
            <RouterLink
              v-else-if="shouldSendPoi || shouldSendPoa"
              :to="{ name: 'myEucapsSettings' }"
              class="px-4 text-white h-14 cursor-pointer flex items-center bg-custom-orange-400 hover:bg-custom-orange-300"
            >
              <Icon
                name="id-card"
                class="mr-2 text-white text-lg"
                solid
              />
              <span class="font-medium">{{ $t('Submit Documents') }}</span>
              <Icon
                name="chevron-right"
                class="ml-2 text-white text-lg"
                solid
              />
            </RouterLink>
            <button
              v-else-if="isKycPending"
              type="button"
              :disabled="stepLoading"
              class="px-4 text-white h-14 cursor-pointer flex items-center bg-custom-orange-400 hover:bg-custom-orange-300 w-full"
              @click="redirectToKyc"
            >
              <div
                v-if="stepLoading"
                class="flex items-center justify-center gap-2"
              >
                <CircleLoader small />
                <div>Please wait..!</div>
              </div>

              <div
                v-else
                class="flex w-full items-center"
              >
                <Icon
                  name="id-card"
                  class="mr-2 text-white text-lg"
                  solid
                />
                <span class="font-medium">{{ $t('Complete your KYC') }}</span>
                <Icon
                  name="chevron-right"
                  class="ml-2 text-white text-lg"
                  solid
                />
              </div>
            </button>
          </div>
          <div
            v-if="isLoggedIn"
            class="flex items-center font-medium border-b dark:border-custom-purple-400 p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-450 cursor-pointer"
            @click="redirect('logout')"
          >
            <Icon
              name="log-out-circle"
              class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
            />
            <span>Log out</span>
          </div>
        </div>
        <!-- <div class=" mt-10 space-x-4 flex justify-center items-end border-t dark:border-custom-purple-400 w-full py-4"> -->
        <div
          v-if="!isLoggedIn"
          class="flex items-center justify-center space-x-4 border-t dark:border-custom-purple-400 w-full py-4"
          :class="showHistory ? 'mt-6' : platformPadding === 'ios-padding' ? 'mt-12' : 'mt-24'"
        >
          <BaseButton
            class="border dark:border-custom-purple-400 rounded-3xl w-36 text-white dark:text-custom-purple-100 font-medium"
            :class="isLoggedIn ? 'bg-custom-orange-400' : 'bg-custom-purple-500'"
            no-shadow
            @click="redirect(isLoggedIn ? 'logout' : 'signup')"
          >
            {{ isLoggedIn ? 'Logout' : 'Sign up' }}
          </BaseButton>
          <BaseButton
            class="border dark:border-custom-purple-400 rounded-3xl w-36 text-white dark:text-custom-purple-100 font-medium bg-custom-purple-500"
            no-shadow
            @click="redirect('login')"
          >
            Login
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import usersMixin from '@/mixins/usersMixin'
import { useCompaniesStore } from '../../stores/companies'
import { useChatsStore } from '../../stores/chats'
import { useUsersStore } from '../../stores/users'
import { useAppsStore } from '../../stores/app'
import { useFiltrationsStore } from '../../stores/filtrations'
import { useDamStore } from '../../stores/dam'
import { useOnboardingsStore } from '@/stores/onboarding'
import TabButton from '../filtrations/_components/TabButton'
import TopTagSearchContainer from '@/modules/topTagSearch'
import headerPaddingMixin from '@/mixins/headerPaddingMixin'
import { isMobile } from '@/js/helpers/mobile'

export default {
  name: 'MyEucapsMenuModal',
  components: {
    TabButton,
    TopTagSearchContainer
  },
  mixins: [usersMixin, headerPaddingMixin],
  props: {
    showMyEucapsMenu: {
      type: Boolean,
      default: false
    }
  },

  emits: ['toggle-my-eucaps-menu'],

  setup() {
    const companiesStore = useCompaniesStore()
    const chatsStore = useChatsStore()
    const usersStore = useUsersStore()
    const appStore = useAppsStore()
    const filtrationsStore = useFiltrationsStore()
    const damStore = useDamStore()
    const onboardingsStore = useOnboardingsStore()

    return { companiesStore, chatsStore, usersStore, appStore, filtrationsStore, damStore, onboardingsStore }
  },
  data() {
    return {
      type: 'myEucapsOverview',
      showHistory: false,
      stepLoading: false
    }
  },
  computed: {
    globalPadding() {
      if (!this.appsStore.newVersionAvailable) {
        // shall only change for header
        return this.platformPadding
      }

      return ''
    },
    history() {
      return this.filtrationsStore.sanitizeHistory
    },
    company() {
      return this.companiesStore.selectedCompany
    },
    allMessages() {
      return this.chatsStore.allMessages
    },
    generalChats() {
      return this.chatsStore.generalChats
    },
    user() {
      return this.usersStore.user
    },
    myEucapsMenu() {
      return this.appStore.myEucapsMenu
    },
    isLoggedIn() {
      return !(this.usersStore.user.profile_type === 'App\\Models\\GuestUser')
    },
    unreadMessages() {
      if (!this.chatsStore.unreadMessages || this.chatsStore.unreadMessages.length === 0) return 0
      let messageCount = 0
      this.chatsStore.unreadMessages.forEach((chat) => {
        if (chat) {
          messageCount += chat
        }
      })
      return messageCount
    },
    selectedTab() {
      if (this.$route.path.includes('forum')) {
        return 'forumAndDiscussions'
      } else if (this.$route.path.includes('history')) {
        return 'history'
      } else if (this.$route.path.includes('explore')) return 'explore'
      else return ''
    },
    parentRoute() {
      if (this.$route.matched.length > 1) {
        return this.$route?.matched[0]?.name
      }
      return this.$route.name
    },
    isVerified() {
      return this.user && this.user.profile && this.user.profile.is_verified
    },
    isInvestor() {
      return this.user && this.user.profile && this.user.is_investor
    },
    isKycPending() {
      return this.user && this.user.profile && this.user.is_kyc_pending
    },
    shouldSendPoi() {
      return this.user && this.user.should_send_poi
    },
    shouldSendPoa() {
      return this.user && this.user.should_send_poa
    },
    kycStep() {
      return this.usersStore?.user?.user_detail?.step ?? 0
    }
  },
  mounted() {
    this.type = this.$route.name
    // Hide chatbox on mobile
    const chatBox = document.getElementById('hubspot-messages-iframe-container')
    if (chatBox && isMobile()) {
      chatBox.id ='hideHubspot'
    }
  },
  beforeUnmount() {
    const chatBox = document.getElementById('hideHubspot')
    if (chatBox && isMobile() ) {
      chatBox.id = 'hubspot-messages-iframe-container'
    }
  },
  methods: {
    redirect(param) {
      if (param === 'logout') this.usersStore.logout()
      else if (param === 'signup') this.$router.push({ name: 'signup' })
      else if (param === 'login') this.$router.push({ name: 'login' })

      this.$emit('toggle-my-eucaps-menu')
    },
    changeTab(tab) {
      this.$router.push({ name: tab })
      this.selectedTab = tab
      this.$emit('toggle-my-eucaps-menu')
    },
    explore(data) {
      if (data.tags.length > 0) {
        this.filtrationsStore.resetScreenerFilter()
        data.tags.forEach((item) => {
          this.filtrationsStore.addTag(item)
        })
        this.$router.replace({
          name: 'exploreResults',
          query: { filtration: JSON.stringify(this.filtrationsStore.getFiltrationUrlQuery) }
        })
      }
      this.$emit('toggle-my-eucaps-menu')
    },
    async redirectToKyc() {
      try {
        this.stepLoading = true
        if (this.kycStep === 1) {
          this.onboardingsStore?.setKycPayload('step', 0)
          await this.onboardingsStore.storeUserDetails()
        }

        this.$router.push({ name: 'kyc' })
        this.stepLoading = false
      } catch (e) {
        console.error(e)
        this.stepLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.light .premium-icon {
  filter: invert(33%) sepia(4%) saturate(1765%) hue-rotate(176deg) brightness(95%) contrast(92%);
}
.dark .premium-icon {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(176deg) brightness(104%) contrast(106%);
}
</style>

<template>
  <div class="z-20 scrollable flex flex-nowrap whitespace-nowrap overflow-x-auto">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Scrollable'
}
</script>

<style scoped>
.scrollable::-webkit-scrollbar {display: none;}
</style>

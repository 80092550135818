import { get, post, destroy } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/notifications`

const list = async () => get(`${url}`)
const remove = async (id) => post(`${url}/${id}/remove`)
const removeAll = async () => destroy(`${url}/all`)
const fetchSettings = async () => get(`${url}/settings`)
const fetchNotificationSettings = async (payload) => get(`${process.env.VUE_APP_API_URL}/users/notifications/settings`, payload)
const setNotificationSettings = async (payload) => post(`${process.env.VUE_APP_API_URL}/users/notifications/settings`, payload)
const scroll = async (link) => get(`${link}`)
const read = async (id) => post(`${url}/${id}/read`)
const hide = async (id) => post(`${url}/${id}/hide`)
const notificationSeen = async (data) => post(`${url}/seen`, data)

export default {
  list,
  remove,
  removeAll,
  fetchSettings,
  fetchNotificationSettings,
  setNotificationSettings,
  scroll,
  read,
  hide,
  notificationSeen
}

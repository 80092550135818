import axios from '../repository'
import qs from 'qs'
import { errorHandler } from '../error'

export const get = async (url, params) => {
  return axios({
    method: 'GET',
    url,
    params
  })
}

export const post = async (url, data) => {
  return axios({
    method: 'POST',
    url,
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      ...data
    })
  })
}

export const upload = async (url, data, config) => {
  return axios({
    method: 'POST',
    url,
    headers: {
      'content-type': 'multipart/form-data'
    },
    data
  })
}
export const put = async (url, data, config) => {
  return axios({
    method: 'PUT',
    url,
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      ...data
    })
  })
}

export const destroy = async (url, params) => {
  return axios({
    method: 'DELETE',
    url,
    params
  })
}

export const downloadBlob = async (url, params) => {
  return axios({
    method: 'GET',
    url,
    params,
    responseType: 'blob'
  })
}

axios.interceptors.response.use(undefined, function (error) {
  error.handleGlobally = errorHandler(error)
  return Promise.reject(error)
})

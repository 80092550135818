<template>
  <div>
    <div class="floating ">
      <slot name="prepend" />
      <input
        :id="'input_'+name"
        class="floating__input"
        :class="classes ? classes : 'border border-gray-300 dark:border-custom-purple-300 rounded-md dark:text-custom-purple-100 dark:bg-custom-purple-450  w-full'"
        :name="'name_'+name"
        :placeholder="placeholder"
        :type="type"
        :value="modelValue"
        :disabled="locked"
        :required="required"
        :autocomplete="autocomplete"
        @input="$emit('update:model-value',$event)"
        @focus="$emit('focus', $event)"
      >

      <label
        :for="'input_'+name"
        class="floating__label text-sm dark:text-custom-purple-100"
        :data-content="placeholder"
      >
        <span class="hidden--visually">
          {{ placeholder }}</span>
      </label>
    </div>
  </div>
</template>
<script>

export default ({
  name: 'FloatingInput',
  props: {
    placeholder: { type: String, default: '' },
    name: { type: String, default: '1' },
    type: { type: String, default: 'text' },
    modelValue: { type: String, default: '' },
    internalClass: { type: Boolean, default: false },
    classes: { type: String, default: '' },
    locked: { type: Boolean, default: false },
    autocomplete: { type: String, default: '' },
    required: { type: Boolean, default: false }
  },
  emits: [
    'update:model-value',
    'focus'
  ]
})
</script>
<style scoped>
.floating {
  transition: background-color 0.2s ease;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.floating__input {
  padding-top: 1.0rem;
  padding-left: 0.5rem;
  padding-bottom:1.0rem;

  transition: border-color 0.2s ease;
  caret-color: var(--color__accent);
}

.floating__input::placeholder {
  color: rgba(0, 0, 0, 0);
}

.floating__label {
  display: block;
  position: relative;
  max-height: 0;

  pointer-events: none;
}

.floating__label::before {
  color: var(--label__color);
  content: attr(data-content);
  display: inline-block;
  filter: blur(0);
  backface-visibility: hidden;
  transform-origin: left top;
  transition: transform 0.2s ease;
  left: 0.5rem;
  position: relative;
}

.floating__label::after {
  content: '';
  position: absolute;
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 0.3s ease;
  opacity: 0;
  left: 0;
  top: 100%;
  margin-top: -0.1rem;
  transform: scale3d(0, 1, 1);
  width: 100%;
  background-color: var(--color__accent);
}

.floating__input:focus + .floating__label::after {
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.floating__input:placeholder-shown + .floating__label::before {
  transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
}

.floating__label::before,
.floating__input:focus + .floating__label::before {
  transform: translate3d(0, -3.12rem, 0) scale3d(0.82, 0.82, 1);
}

.floating__input:focus + .floating__label::before {
  color: var(--color__accent);
}
.floating__input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.floating__input[type="number"]::-webkit-inner-spin-button,
.floating__input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hidden--visually {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
</style>

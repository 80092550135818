<template>
  <div class="p-2 cursor-pointer dark:hover:bg-custom-purple-400 hover:bg-gray-100">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'ListItemContent'
}
</script>

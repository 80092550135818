<template>
  <div
    :class="[secondary ? 'font-standard' : 'font-header', bold, color, size]"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Title',
  props: {
    secondary: {
      type: Boolean,
      default: false
    },
    bold: {
      type: String,
      default: 'font-extrabold'
    },
    size: {
      type: String,
      default: 'text-xl'
    },
    color: {
      type: String,
      default: 'text-custom-purple-500 dark:text-custom-purple-100'
    }
  }
}
</script>

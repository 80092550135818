import { get } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const devcode = async () => get(`${url}/idhash`)

const auth = async (payload) => get(`${url}/auth`, payload)

export default {
  devcode,
  auth
}

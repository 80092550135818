<template>
  <img
    class="svg-filter-custom-purple-100"
    :class="size"
    src="@/assets/svg/save-to-list.svg"
  >
</template>

<script>
export default {
  name: 'SaveToList',
  props: {
    size: { type: String, default: 'w-4 h-4' }
  }
}
</script>

<style scoped>
.dark .svg-filter-custom-purple-100 {
  filter: invert(100%) sepia(54%) saturate(1596%) hue-rotate(210deg) brightness(109%) contrast(98%);
}
</style>

<template>
  <div class="bg-custom-purple-400 p-4 text-center w-full h-full flex flex-col items-center justify-center">
    <ColoredLogo
      :square-size="300"
      class="mb-8"
    />

    <Title
      class="text-xl lg:text-2xl font-extrabold mb-2"
      color="text-white"
      no-shadow
    >
      Your current App version is to old.
    </Title>
    <p class="text-base lg:text-lg font-medium text-white">
      Please go to your Marketplace and upgrade the app for the latest updates!
    </p>
  </div>
</template>

<script>

export default {
  name: 'ForcedUpgradeMessage'
}
</script>

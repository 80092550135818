<template>
  <div class="rounded-b-md shadow-none bg-white dark:bg-custom-purple-400 dark:text-custom-purple-100 flex p-10">
    <div class="w-1/3 text-2xl font-bold">
      <p>Your search</p>
      <p>History</p>
    </div>
    <div class="w-2/3">
      <div v-if="history.length === 0">
        <p class="text-lg text-center my-5">
          No history found
        </p>
      </div>
      <ul v-else>
        <li
          v-for="item in history"
          :key="item.id"
          @click="$emit('explore', item)"
        >
          <div class="hover:text-custom-orange-400 text-lg cursor-pointer flex items-start justify-start my-3">
            <div class="font-bold mr-3 w-16 flex justify-end">
              <span>{{ item.result_count }}</span> 
              <span class="ml-2">results:</span>
            </div>
            <div class="flex flex-wrap gap-x-2">
              <p
                v-for="t,index in item.tags"
                :key="index"
              >
                {{ t.label }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'History',
  props: {
    history: {
      type: Array,
      default: () => []
    }
  },
  emits: ['explore'],
}
</script>
<template>
  <RouterLink :to="to">
    <PositiveButton>
      <slot />
    </PositiveButton>
  </RouterLink>
</template>

<script>

export default {
  name: 'PositiveLink',
  props: {
    to: { type: String, default: '', required: true },
    secondary: { type: Boolean, default: false }
  },
  computed: {
    isSecondary () {
      return (this.secondary !== false)
    }
  }
}
</script>

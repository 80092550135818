<template>
  <div class="page flex flex-grow flex-shrink-0 flex-col overflow-hidden bg-custom-purple-500 dark:bg-custom-purple-500 dark:text-primary-dark shadow-reverse dark:shadow-sm-dark">
    <main class="flex flex-col flex-grow flex-shrink-0 z-10">
      <RouterView :key="$route.fullPath" />
    </main>
    <PortalTarget
      name="dialog"
      multiple
    />
  </div>
</template>

<script>

export default {
  name: 'SignInLayout',
  components: {
  }
}
</script>
